import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_DRILLING_LIMITS,
} from '../actions/data';

export default function limits(state, action) {
  if (typeof state === 'undefined') {
      return {
          validData: false, 
          startDepth: 0,
          endDepth: 0,
          ropMin: 0,
          ropMax: 0,
          wobMin: 0,
          wobMax: 0,
          diffPMin: 0,
          diffPMax: 0,
          torqueMin: 0,
          torqueMax: 0,
          rotateMin: 0,
          rotateMax: 0,
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
      case SOCKET_ON_DRILLING_LIMITS:
          newState.validData = true;
          newState.startDepth = action.drillingLimits.startDepth
          newState.endDepth = action.drillingLimits.endDepth
          newState.ropMin = action.drillingLimits.ropMin
          newState.ropMax = action.drillingLimits.ropMax
          newState.wobMin = action.drillingLimits.wobMin
          newState.wobMax = action.drillingLimits.wobMax
          newState.diffPMin = action.drillingLimits.diffPMin
          newState.diffPMax = action.drillingLimits.diffPMax
          newState.torqueMin = action.drillingLimits.torqueMin
          newState.torqueMax = action.drillingLimits.torqueMax
          newState.rotateMin = action.drillingLimits.rotateMin
          newState.rotateMax = action.drillingLimits.rotateMax
      return newState;
    default:
      return state;
  }
}
