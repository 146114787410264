import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

class DrillingCalibrationApplication extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <React.Fragment>
        <Card raised className="wellbore-overview-card" style={{height: "370px"}}>
          <CardHeader title="Drilling Calibration"
                      titleTypographyProps={{ align: "center", variant: "h6"}}
            />
          <CardContent>
            <Grid container direction="row" xs={12} spacing={2}>
              <Grid item xs={4} >
                  <Card>
                      <CardHeader title="WOB"
                                  titleTypographyProps={{ align: "center", variant: "subtitle1"}}
                       />
                      <CardContent>
                          <Grid item container xs={12}>
                              <Grid item xs={6}>
                                  <Typography className="objective-label" align="right" >Proportional:</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                  <Typography className="objective-value" align="right">{this.props.request.wobProportional}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                  <Typography className="objective-label" align="right" >Integral:</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                  <Typography className="objective-value" align="right">{this.props.request.wobIntegral}</Typography>
                              </Grid>
                          </Grid>
                      </CardContent>
                  </Card>
              </Grid>

              <Grid item xs={4} >
                  <Card>
                      <CardHeader title="Diff Pressure"
                                  titleTypographyProps={{ align: "center", variant: "subtitle1"}}
                       />
                      <CardContent>
                          <Grid item container xs={12}>
                              <Grid item xs={6}>
                                  <Typography className="objective-label" align="right" >Proportional:</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                  <Typography className="objective-value" align="right">{this.props.request.differentialPressureProportional}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                  <Typography className="objective-label" align="right" >Integral:</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                  <Typography className="objective-value" align="right">{this.props.request.differentialPressureIntegral}</Typography>
                              </Grid>
                          </Grid>
                      </CardContent>
                  </Card>
              </Grid>

              <Grid item xs={4} >
                  <Card>
                      <CardHeader title="Torque"
                                  titleTypographyProps={{ align: "center", variant: "subtitle1"}}
                       />
                      <CardContent>
                          <Grid item container xs={12}>
                              <Grid item xs={6}>
                                  <Typography className="objective-label" align="right" >Proportional:</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                  <Typography className="objective-value" align="right">{this.props.request.torqueProportional}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                  <Typography className="objective-label" align="right" >Integral:</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                  <Typography className="objective-value" align="right">{this.props.request.torqueIntegral}</Typography>
                              </Grid>
                          </Grid>
                      </CardContent>
                  </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

DrillingCalibrationApplication.propTypes = {
  drilling: PropTypes.bool,
  socket: PropTypes.object,
};
export default DrillingCalibrationApplication;
