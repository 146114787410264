import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import { angularVelocity } from "units-converter";
import { velocity } from "units-converter";
import { force } from "units-converter";

import RequestsModalContainer from '../RoptimizerConfiguration/RequestsModalContainer';
import DigitalDisplay from './DigitalDisplay/DigitalDisplay';

class RoptimizerApplication extends React.Component {
  constructor(props) {
    super(props);

    this.toggleMseTrend = this.toggleMseTrend.bind(this);
    this.toggleBitwearTrend = this.toggleBitwearTrend.bind(this);
    this.togglePowerTrend = this.togglePowerTrend.bind(this);
    this.registerListeners = this.registerListeners.bind(this);
    this.openConfigurationModal = this.openConfigurationModal.bind(this);
    this.requestModalRef = React.createRef();
  }

  toggleMseTrend(name) {
    this.props.toggleTrend("mse", !this.props.displayMseTrend);
  }

  toggleBitwearTrend(name) {
    this.props.toggleTrend("bitwear", !this.props.displayBitwearTrend);
  }

  togglePowerTrend(name) {
    this.props.toggleTrend("power", !this.props.displayPowerTrend);
  }

  componentDidMount() {
    if (this.props.socket) {
      this.registerListeners();
    } else {
      let that = this;
      setTimeout(function () {
        that.registerListeners();
      }, 300);
    }
  }

  componentWillUnmount() {
    if (this.props.socket) {
      this.props.stopRoptimizerListeners(this.props.socket);
    }
  }

  openConfigurationModal() {
    this.requestModalRef.current.openModal();
  }

  registerListeners() {
    if (this.props.socket) {
      this.props.watchRoptimizerConfiguration(this.props.socket);
    } else {
      let that = this;
      console.log('Socket not connected');
      setTimeout(function () {
        that.registerListeners();
      }, 100);
    }
  }

  render() {
      const drillRequest = this.props.drillRequest;
      const rotateRequest = this.props.rotateRequest;
      const steadyState = this.props.steadyState;

      const angularVelocityUom = localStorage.getItem("angularVelocity");
      var rpmTarget = angularVelocity(rotateRequest.targetRate).from('rad/s').to(angularVelocityUom);

      const velocityUom = localStorage.getItem("velocity");
      var ropTarget = velocity(drillRequest.ropTarget).from('m/s').to(velocityUom);

      const forceUom = localStorage.getItem("force");
      var wobTarget = force(drillRequest.wobTarget).from('N').to(forceUom);

    return (
      <React.Fragment>
        <Card raised className="wellbore-overview-card" style={{height: "370px"}}>
          <CardHeader title="Roptimizer"
                      titleTypographyProps={{ align: "center", variant: "h6"}}
              action={
                <Tooltip title="Roptimzier Configuration" placement="top">
                  <IconButton
                    color="inherit"
                    aria-label="Open Configuration Requests Modal"
                    onClick={this.openConfigurationModal} >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
            }
	    />
          <CardContent>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} container spacing={2} alignItems="center" justify="space-evenly">
                <DigitalDisplay title="RPM" value={Number.parseFloat(rpmTarget.value).toFixed()}/>
                <DigitalDisplay title="ROP" value={Number.parseFloat(ropTarget.value).toFixed()}/>
                <DigitalDisplay title="WOB" value={Number.parseFloat(wobTarget.value).toFixed()}/>
              </Grid>
              <Grid item xs={12} container spacing={2} alignItems="center" justify="space-evenly">
                <DigitalDisplay title="MSE" trend={true} toggleTrend={this.toggleMseTrend} displayTrend={this.props.displayMseTrend} value={Number.parseFloat(steadyState.mse).toFixed()} />
                <DigitalDisplay title="Bit Wear" trend={true} toggleTrend={this.toggleBitwearTrend} displayTrend={this.props.displayBitwearTrend} value={Number.parseFloat(steadyState.bitWear).toFixed()}/>
                <DigitalDisplay title="Power" trend={true} toggleTrend={this.togglePowerTrend} displayTrend={this.props.displayPowerTrend} value={Number.parseFloat(steadyState.power).toFixed()} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
       <RequestsModalContainer ref={this.requestModalRef} />
      </React.Fragment>
    );
  }
}

RoptimizerApplication.propTypes = {
  drilling: PropTypes.bool,
  socket: PropTypes.object,
};
export default RoptimizerApplication;
