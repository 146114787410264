import { connect } from 'react-redux';
import RoptimizerApplication from './roptimizerCard';

import {
  newRoptimizerConfiguration,
  setDisplayTrends,
} from '../../redux/actions/data';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
      drilling: state.rigObjective.objective === 4,
      socket: state.connection.socket,
      drillRequest: state.drillRequestData,
      rotateRequest: state.rotateRequestData,
      steadyState: state.roptimizerSteadyState,
      displayMseTrend: state.displayTrends["mse"].display,
      displayBitwearTrend: state.displayTrends["bitwear"].display,
      displayPowerTrend: state.displayTrends["power"].display,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    watchRoptimizerConfiguration(socket) {
      socket.on('roptimizerConfiguration', (roptimizerData) => {
        dispatch(newRoptimizerConfiguration(roptimizerData));
      });
    },
    toggleTrend(trend, display) {
        dispatch(setDisplayTrends(trend, display));
    },
    stopRoptimizerListeners(socket) {
      socket.removeListener('roptimizerConfiguration', (roptimizerData) => {
        dispatch(newRoptimizerConfiguration(roptimizerData));
      });
    },
  };
};

const RoptimizerApplicationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoptimizerApplication);

export default RoptimizerApplicationContainer;
