import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Snackbar,
} from '@material-ui/core';

import Dropzone from 'react-dropzone-uploader';
import CloseIcon from '@material-ui/icons/Close';

import './FileUploadModal.css';
import 'react-dropzone-uploader/dist/styles.css';

class FileUploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isSnackbarOpen: false,
      snackbarMessage: '',
      snackbarColor: '',
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.snackbarClose = this.snackbarClose.bind(this);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
    });
  }

  snackbarClose() {
    this.setState({
      isSnackbarOpen: false,
    });
  }

  getUploadParams({ meta }) {
    return {
      url: 'http://75.63.109.99:35000/upload',
      fields: {
        filename: meta.name,
      },
      method: 'post',
    };
  }

  handleChangeStatus({ meta, remove }, status) {
    console.log(status);
    if (status === 'headers_received') {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: meta.name + ' uploaded successfully!',
        snackbarColor: '#138614',
      });
      remove();
    } else if (status === 'aborted' || status === 'exception_upload') {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: meta.name + ' upload failed.',
        snackbarColor: '#9a1f1b',
      });
    }
    return;
  }

  render() {
    let that = this;
    return (
      <Modal
        id="upload-data-file-modal"
        aria-labelledby="Upload Data File Modal"
        aria-describedby="Upload new data file to simulator"
        open={this.state.isModalOpen}
        onClose={this.closeModal}
      >
        <Card id="upload-data-file-modal-content">
          <CardHeader
            title="Upload Data File"
            action={
              <IconButton
                key="close-upload-modal"
                color="inherit"
                aria-label="Close Upload Modal"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Dropzone
              getUploadParams={this.getUploadParams}
              onChangeStatus={this.handleChangeStatus}
              maxFiles={1}
              multiple={false}
              canCancel={false}
              inputContent="Drop or Click to Select a File"
              styles={{
                dropzone: { width: 400, height: 400 },
                dropzoneActive: { borderColor: 'green' },
              }}
            />

            <Snackbar
              ContentProps={{ style: { background: that.state.snackbarColor } }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={this.state.isSnackbarOpen}
              autoHideDuration={6000}
              onClose={this.snackbarClose}
              message={<span>{this.state.snackbarMessage}</span>}
              action={
                <IconButton
                  key="close-snackbar"
                  aria-label="Close notification"
                  color="inherit"
                  onClick={this.snackbarClose}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
          </CardContent>
        </Card>
      </Modal>
    );
  }
}

export default FileUploadModal;
