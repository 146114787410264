import { connect } from 'react-redux';
import Layout from './layout';
import { requestLogin, logoutUser } from '../../redux/actions/auth';
import {
    login,
    logout,
} from '../../redux/actions/data';

const mapStateToProps = function mapStateToProps(state) {
    return {
	activeRigs: state.activeRigs,
	loggedIn: state.auth.loggedIn,
	drilling: state.rigObjective.objective === 4,
	objective: state.rigObjective.objective,
	connected: state.connection.connected,
	socket: state.connection.socket,
  };
};

const mapDispatchToProps = function (dispatch) {
    return {
	login(profile) {
	    dispatch(requestLogin(profile));
	},
	logout() {
	    dispatch(logoutUser());
	},
    };
};

const LayoutContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);

export default LayoutContainer;
