import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress  from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import clsx from 'clsx';
import { ExportToCsv } from 'export-to-csv';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector} from "react-redux";
import { eraseObjectiveData } from "../../redux/actions/data";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonCompleted: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 0,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function DownloadList(props) {
    const classes = useStyles();
    const [item, setItem] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const [download, setDownload] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const dispatch = useDispatch();

    const handleClose = () => {
	setOpen(false);
	setLoading(false);
	props.closeModal();
    };
    
    const buttonClassname = clsx({
	[classes.buttonCompleted]: completed,
    });

    const downloadData = () => {
	if (!loading) {
	    setCompleted(false);
	    setDownload(false);
	    setLoading(true);
	    props.getHistoricalData(props.objectives[item]);
	}
    };

    const objectiveData = useSelector(state => state.objectiveData.objectiveData);

    React.useEffect(() => {
	if (download === true)
	{
	    downloadData();
	}
    }, [download, downloadData]);

    React.useEffect(() => {
	if (objectiveData.length > 0) {
	    setCompleted(true);
	    setLoading(false);
	    options.filename = props.objectives[item].name;
	    var csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(objectiveData);
	    dispatch(eraseObjectiveData());
	    if (item + 1 < props.objectives.length) {
   	        setItem(item + 1);
		setDownload(true);
	    }
	} else {
	    setCompleted(false);
	}
	
    }, [objectiveData]);

    const options = {
	fieldSeparator: ',',
	quoteStrings: '"',
	decimalSeparator: '.',
	showLabels: true, 
	showTitle: true,
	title: 'Historical Data',
	useTextFile: false,
	useBom: true,
	useKeysAsHeaders: true,
    };

    return (
      <div className={classes.root}>
          <Dialog id="requests-modal"
              aria-labelledby="Download Historical Data"
              aria-describedby="View current values and set targets for system configurations."
              open={open}
              onClose={handleClose} >
            <DialogTitle>Downloading</DialogTitle>
            <DialogContent>
              <List>
            {props.objectives.map(objective => (
		<div>
  	          <ListItem key={objective.id} >
  	            <ListItemIcon >
	              <Fab size="small">
			{completed ? <CheckIcon size="small"/> : <CloudDownloadIcon size="small" />}
                        {loading && <CircularProgress size={49} className={classes.fabProgress} />}
	              </Fab>
  	            </ListItemIcon >
	          <ListItemText primary={objective.name} />
			</ListItem>
		    <Divider />
		    </div>
	        ))}
  	      </List>
            </DialogContent>
            <DialogActions>
              <Button variant="contained"
                      color="primary"
                      className={buttonClassname}
                      onClick={downloadData} >
                Download
              </Button>
              <Button variant="contained"
                      color="primary"
                      className={buttonClassname}
                      onClick={handleClose} >
                Cancel
              </Button>
            </DialogActions>
         </Dialog>
      </div>
  );
}
