import { connect } from 'react-redux';
import ResourcesSnackbarManager from './resourcesSnackbarManager';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    connected: state.connection.connected,
    resources: state.resources,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {};
};

const ResourcesSnackbarManagerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourcesSnackbarManager);

export default ResourcesSnackbarManagerContainer;
