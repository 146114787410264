import React from 'react';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

export function createInputPair(
  fieldName,
  propFieldVal,
  stateFieldVal,
  updateFieldCb,
) {
  let label = fieldName.split(/(?=[A-Z])/).join(' ');
  label = label[0].toUpperCase() + label.slice(1);

  return (
    <React.Fragment>
      <Grid item>
        <div>
          <TextField
            id={fieldName}
            label={label}
            variant="outlined"
            disabled
            value={propFieldVal}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </div>
      </Grid>
      <Grid item>
        <div>
          <TextField
            id={label}
            label={label}
            variant="outlined"
            value={stateFieldVal}
            onChange={updateFieldCb(fieldName)}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </div>
      </Grid>
    </React.Fragment>
  );
}

export function createInputWithTooltip(
  id,
  label,
  propFieldVal,
  stateFieldVal,
  updateFieldCb,
) {

  return (
    <React.Fragment>
      <Grid item>
          <Tooltip
            title={'Current: ' + propFieldVal}
            interactive
            placement="top"
            arrow
          >
            <TextField
              id={id}
              label={label}
              variant="outlined"
              defaultValue={stateFieldVal}
              onChange={updateFieldCb(id)}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
          </Tooltip>
      </Grid>
    </React.Fragment>
  );
}

export function createInputWithTooltipUnit(
  id,
  label,
  unit,
  propFieldVal,
  stateFieldVal,
  updateFieldCb,
) {

  return (
    <React.Fragment>
      <Grid item>
        <div>
          <Tooltip
            title={'Current: ' + propFieldVal}
            interactive
            placement="top"
            arrow
          >
            <TextField
              id={id}
              label={label}
              variant="outlined"
              defaultValue={stateFieldVal}
              onChange={updateFieldCb}
              type="number"
	      InputProps={{
	        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
	      }}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
          </Tooltip>
        </div>
      </Grid>
    </React.Fragment>
  );
}

export function createDisabledInput(fieldName, propFieldVal) {
  let label = fieldName.split(/(?=[A-Z])/).join(' ');
  label = label[0].toUpperCase() + label.slice(1);

  return (
    <React.Fragment>
      <Grid item>
        <div>
          <TextField
            id={fieldName}
            label={label}
            variant="outlined"
            disabled
            value={propFieldVal}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </div>
      </Grid>
    </React.Fragment>
  );
}

export function createCheckboxPair(
  fieldName,
  propFieldVal,
  stateFieldVal,
  updateFieldCb,
) {
  let label = fieldName.split(/(?=[A-Z])/).join(' ');
  label = label[0].toUpperCase() + label.slice(1);

  return (
    <React.Fragment>
      <Grid item>
        <FormControlLabel
          control={<Checkbox checked={propFieldVal} disabled />}
          label={label}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={stateFieldVal}
              onChange={updateFieldCb(fieldName)}
              value={fieldName}
            />
          }
          label={label}
        />
      </Grid>
    </React.Fragment>
  );
}

export function createCheckboxWithTooltip(
  id,
  fieldName,
  propFieldVal,
  stateFieldVal,
  updateFieldCb,
) {
  let label = fieldName.split(/(?=[A-Z])/).join(' ');
  label = label[0].toUpperCase() + label.slice(1);

  return (
    <React.Fragment>
      <Grid item>
        <Tooltip
          title={'Current: ' + propFieldVal}
          interactive
          placement="top"
          arrow
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={stateFieldVal}
                onChange={updateFieldCb(id)}
                value={fieldName}
              />
            }
            label={label}
          />
        </Tooltip>
      </Grid>
    </React.Fragment>
  );
}

export function createDisabledCheckbox(fieldName, propFieldVal) {
  let label = fieldName.split(/(?=[A-Z])/).join(' ');
  label = label[0].toUpperCase() + label.slice(1);

  return (
    <React.Fragment>
      <Grid item>
        <FormControlLabel
          control={<Checkbox checked={propFieldVal} disabled />}
          label={label}
        />
      </Grid>
    </React.Fragment>
  );
}

export function createSubmitCloseButtons(
  submitConfigurationRequestCb,
  closeModalCb,
) {
  return (
    <CardActions>
      <Grid container spacing={8} justify="center">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            onClick={submitConfigurationRequestCb}
          >
            Update
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            onClick={closeModalCb}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </CardActions>
  );
}

export function createSubmitButton(
  id,
  submitConfigurationRequestCb,
) {
  return (
    <CardActions>
      <Grid container spacing={8} justify="center">
        <Grid item>
          <Button
              id={id}
              color="primary"
              variant="contained"
              size="medium"
              onClick={submitConfigurationRequestCb}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </CardActions>
  );
}
