import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const isChrome = typeof window.chrome === 'object' && navigator.appVersion.indexOf('Edge') === -1;

let store;
if (process.env.NODE_ENV === 'development') {
  if (isChrome) {
    store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
  } else {
    store = createStore(rootReducer, compose(applyMiddleware(thunk)));
  }
} else {
    store = createStore(rootReducer, compose(applyMiddleware(thunk)));
}

export default store;
