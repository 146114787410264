import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_ERASE_OBJECTIVE_DATA,
  SOCKET_ON_ERASE_VALID_OBJECTIVES,
  SOCKET_ON_OBJECTIVE_DATA,
  SOCKET_ON_VALID_OBJECTIVES,
} from '../actions/data';

export default function objectiveData(state, action) {
    if (typeof state === 'undefined') {
	return {
	    numObjectives: -1,
	    numData: -1,
	    objectiveData: [],
	    validObjectives: [],
	};
    }

    const newState = deepmerge({}, state);

    switch (action.type) {
        case SOCKET_ON_ERASE_OBJECTIVE_DATA:
	    newState.objectiveData = [];
	    return newState;
        case SOCKET_ON_ERASE_VALID_OBJECTIVES:
	    newState.validObjectives = [];
	    return newState;
        case SOCKET_ON_OBJECTIVE_DATA:
	    newState.numData = action.objectiveData.length;
	    newState.objectiveData = [...action.objectiveData];
   	    return newState;
        case SOCKET_ON_VALID_OBJECTIVES:
	    newState.numObjectives = action.validObjectives.length;
	    newState.validObjectives = [...action.validObjectives];
	    return newState;
        default:
	    return state;
    }
}
