import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LensIcon from '@material-ui/icons/Lens';
import React from 'react';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { format } from "d3-format"

export default class ProcessView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const green = '#0f9803';
    const red = '#900606';

    let ledColor = (this.props.resources[this.props.name].alive) ? green: red;

    return (
        <Grid item xs={6} sm={4} md={3} lg={2}>
            <Card className="resources-process-card">
                <CardHeader align="center"
                            title={this.props.name}
                            action={<LensIcon className="lens-led" style={{ color: ledColor }} />}
	        />
                <CardContent>
                    <Grid item container xs={12} outline>
                        <Grid item xs={6}>
                            <Typography align="left">Version:</Typography>
                        </Grid>
	                <Grid item xs={6}>
	                    <Typography align="right">{this.props.resources[this.props.name].appVersion} </Typography>
	                </Grid>
                        <Grid item xs={6}>
                            <Typography align="left">PID:</Typography>
                        </Grid>
	                <Grid item xs={6}>
	                    <Typography align="right">{this.props.resources[this.props.name].pid}</Typography>
	                </Grid>
                        <Grid item xs={6}>
                            <Typography align="left">CPU:</Typography>
                        </Grid>
	                <Grid item xs={6}>
			    <Typography align="right">{ format("20,.1f")(this.props.resources[this.props.name].cpuPercent)} %</Typography>
	                </Grid>
	                <Grid item xs={6}>
	                    <Typography align="left">Up Time:</Typography>
	                </Grid>
	                <Grid item xs={6}>
	                    <Typography align="right">{format("20,.0f")(this.props.resources[this.props.name].upTime)}</Typography>
	                </Grid>
                    </Grid>
                </CardContent>
	        <CardActions>
                    <Tooltip title="Historical Trend" placement="bottom">
                        <IconButton size="small" >
                            <TimelineIcon fontSize="small"
      	                                  color={this.state.expanded === true ? "secondary": "inherit"}
			                  onClick={this.handleExpandClick} />
	                </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
        </Grid>
    );
  };
}
