import deepmerge from '../../helpers/deepmerge';
import {
    ACTIVE_RIGS,
} from '../actions/auth';

export default function activeRigs(state, action) {
  if (typeof state === 'undefined') {
      return {
          valid: false,
          rigs: [],
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
      case ACTIVE_RIGS:
          newState.valid = true;
          newState.rigs = [...action.rigs];
      return newState;
    default:
      return state;
  }
}
