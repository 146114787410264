import React from 'react';
import { Redirect } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import './drillLimitsView.css';
import {
    createInputWithTooltipUnit,
    createSubmitCloseButtons,
} from '../Common/CreateInputsHelperMethods';

export default class DrillLimitsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      startDepth: props.limits.startDepth,
      endDepth: props.limits.endDepth,
      ropMin: props.limits.ropMin,
      ropMax: props.limits.ropMax,
      wobMin: props.limits.wobMin,
      wobMax: props.limits.wobMax,
      diffPMin: props.limits.diffPMin,
      diffPMax: props.limits.diffPMax,
      torqueMin: props.limits.torqueMin,
      torqueMax: props.limits.torqueMax,
      rotateMin: props.limits.rotateMin,
      rotateMax: props.limits.rotateMax,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitDrillingLimitsConfigurationRequest = this.submitDrillingLimitsConfigurationRequest.bind(this);
    this.updateInput = this.updateInput.bind(this);
  }

  updateInput(name) {
    let that=this;
    return function (event) {
            that.setState({ [name]: parseInt(event.target.value) });
        };
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  componentDidUpdate(prevProps, prevState) {
      console.log('drillLimitsView::componentDidUpdate()');
  }
  componentDidMount() {
      console.log('drillLimitsView::componentDidMount()');
  }

  submitDrillingLimitsConfigurationRequest() {
      let configurationData = {
          startDepth: this.state.startDepth,
          endDepth: this.state.endDepth,
          ropMin: this.state.ropMin,
          ropMax: this.state.ropMax,
          wobMin: this.state.wobMin,
          wobMax: this.state.wobMax,
          diffPMin: this.state.diffPMin,
          diffPMax: this.state.diffPMax,
          torqueMin: this.state.torqueMin,
          torqueMax: this.state.torqueMax,
          rotateMin: this.state.rotateMin,
          rotateMax: this.state.rotateMax,
      };
      this.props.socket.emit('DrillingLimitsConfigurationRequest', configurationData);
  }

  render() {
      if (process.env.REACT_APP_USE_AUTH === 'true' && !this.props.loggedIn) {
          return <Redirect to={'/'} push />;
      }
      const config = this.props.limits;

      return (
          <Dialog id="requests-modal"
            aria-labelledby="Wellplan Settings Modal"
	    aria-describedby="View current values and set targets for system configurations."
            open={this.state.isModalOpen}
            onClose={this.closeModal}
            fullWidth
            maxWidth="md"
	>
            <DialogTitle>Drilling Limits</DialogTitle>
            <DialogContent>
            <div id="well-plan-content">
	       <Card className="configuration-request-card" variant="outlined">
	        <CardContent>
                <Grid container
                      justify="center"
                      display="row"
                      alignItems="center"
                      spacing={4} >
                  {createInputWithTooltipUnit(
                      'startDepth',
                      'Start Depth',
                      'ft',
                      config.startDepth,
                      this.state.startDepth,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'endDepth',
                      'End Depth',
                      'ft',
                      config.endDepth,
                      this.state.endDepth,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'ropMin',
                      'ROP Min',
                      'ft/hr',
                      config.ropMin,
                      this.state.ropMin,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'ropMax',
                      'ROP Max',
                      'ft/hr',
                      config.ropMax,
                      this.state.ropMax,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'wobMin',
                      'WOB Min',
                      'Klbs',
                      config.wobMin,
                      this.state.wobMin,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'wobMax',
                      'WOB Max',
                      'Klbs',
                      config.wobMax,
                      this.state.wobMax,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'diffPMin',
                      'Diff PMin',
                      'psi',
                      config.diffPMin,
                      this.state.diffPMin,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'diffPMax',
                      'Diff PMax',
                      'psi',
                      config.diffPMax,
                      this.state.diffPMax,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'torqueMin',
                      'Torque Min',
                      'Nm',
                      config.torqueMin,
                      this.state.torqueMin,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'torqueMax',
                      'Torque Max',
                      'Nm',
                      config.torqueMax,
                      this.state.torqueMax,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'rotateMin',
                      'RPM Min',
                      'RPM',
                      config.rotateMin,
                      this.state.rotateMin,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'rotateMax',
                      'RPM Max',
                      'RPM',
                      config.rotateMax,
                      this.state.rotateMax,
                      this.updateInput,
                  )}
   	       </Grid>
	       </CardContent>
	         {createSubmitCloseButtons(
                    this.submitDrillingLimitsConfigurationRequest,
                    this.closeModal,
                 )}
              </Card>
            </div>
            </DialogContent>
          </Dialog>
        );
    }
}
