import { connect } from 'react-redux';

import SteadyStateIndicator from './SteadyStateIndicator';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  const property = ownProps.type + 'SteadyState';
  const params = {
    title: ownProps.type,
    steadyState: state.roptimizerSteadyState[property],
    drilling: ownProps.drilling,
  };
  return params;
};

const mapDispatchToProps = function (dispatch) {
  return {};
};

const SteadyStateIndicatorSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SteadyStateIndicator);

export default SteadyStateIndicatorSelectorContainer;
