import deepmerge from '../../helpers/deepmerge';
import {
    SOCKET_ON_NEW_RIG_OBJECTIVE,
} from '../actions/data';

const objectives = [
    "InitialState",
    "None",
    "Casing",
    "CleaningHole",
    "Drilling",
    "Sliding",
    "AutoReaming",
    "Tripping",
    "AddStand",
];

export default function rigObjective(state, action) {
  if (typeof state === 'undefined') {
    return {
      id: null, // Defines which components will update
      parentId: null,
      timestamp: -1,
      objective: 0,
      objectiveStr: "InitialState",
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
  case SOCKET_ON_NEW_RIG_OBJECTIVE:
      newState.id = action.rigObjective.id;
      newState.parentId = action.rigObjective.parentId;
      newState.timestamp = new Date(action.rigObjective.timestamp.sec * 1000);
      newState.objective = action.rigObjective.objective;
      newState.objectiveStr = objectives[action.rigObjective.objective];
      return newState;
    default:
      return state;
  }
}
