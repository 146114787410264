import React, { useState, useEffect } from 'react';

const Clock = (props) => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);

  function getDuration()
  {
      var sec_num = parseInt(seconds);
      var hh   = Math.floor(sec_num / 3600);
      var mm = Math.floor((sec_num - (hh * 3600)) / 60);
      var ss = sec_num - (hh * 3600) - (mm * 60);        
      if (hh   < 10) {hh   = "0"+hh;}
      if (mm < 10) {mm = "0"+mm;}
      if (ss < 10) {ss = "0"+ss;}
      var time    = hh+':'+mm+':'+ss;

      return time;
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((Date.now() - props.startTime) / 1000);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
      <div className="time">
      {getDuration()}
      </div>
  );
};

export default Clock;