import './appBarAndDrawer.css';
import AppBar from '@material-ui/core/AppBar';
import AutoTunerDialog from '../AutoTuner/autoTunerDialog';
import Avatar from '@material-ui/core/Avatar';
import ColorLensIcon from '@material-ui/icons/ColorLensOutlined';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FileUploadModalContainer from '../FileUploadModal/FileUploadModalContainer';
import HistoricalViewDialog from '../HistoricalView/historicalViewDialog';
import UserConfigDialog from '../UserConfiguration/userConfigDialog';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Logo from '../../assets/img/logo-dark.png';
import LogoutIcon from '@material-ui/icons/KeyboardReturnOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import Routes from '../../routes';
import Select from 'react-select';
import SimulatorRemote from './SimulatorView.js';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';

class AppBarAndDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      chosenRig: null,
      drilling: props.drilling,
      initiateConnection: true,
      name: '',
      email: '',
      picture: '',
    };

    this.getDrawer = this.getDrawer.bind(this);
    this.manageDrawer = this.manageDrawer.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.openFileUploadModal = this.openFileUploadModal.bind(this);
    this.logout = this.logout.bind(this);
    this.changeRig = this.changeRig.bind(this);
    this.handleDrillingRequest = this.handleDrillingRequest.bind(this);

    this.fileUploadModalRef = React.createRef();
  }

  changeRig(selection) {
    localStorage.setItem('chosenRig', JSON.stringify(selection));
    this.setState({ chosenRig: selection,
    		    initiateConnection : !this.state.initiateConnection});
    this.props.disconnectFromServer(this.props.socket);
    this.props.resetStore();	
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loggedIn && this.props.rigs.valid) {
        if (typeof this.state.chosenRig === 'undefined') {
	   this.setState({chosenRig: this.props.rigs.rigs[0]});
	}
        if ((this.props.connected === false) && (this.state.initiateConnection === true) && (typeof this.state.chosenRig !== 'undefined')) {
//         console.log("AppBarAndDrawer::componentDidUpdate - Connect To Server ", this.state.chosenRig.label, this.state.chosenRig.value);
           this.setState({initiateConnection : !this.state.initiateConnection });
           this.props.connectToServer(this.state.chosenRig);
       } else if ((this.props.connected === false) && (prevProps.connected === true)) {
           console.log('Resetting State');
           this.props.resetStore();     
           this.setState({initiateConnection : true });
       }
    }
 }

  handleDrillingRequest() {
    if (this.props.drilling) {
      this.props.stopDrilling();
    } else {
      this.props.startDrilling();
    }
    this.setState({ drilling: !this.props.drilling });
  }

  toggleDrawer() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  logout() {
    this.toggleDrawer();

    localStorage.removeItem('accessToken');
    localStorage.removeItem('profile');
    localStorage.removeItem('user');
    localStorage.removeItem('email');
    localStorage.removeItem('selection');

    this.props.lock.logout({
      returnTo: process.env.REACT_APP_UI_HOST_SERVER,
    });
  }

  getRouteName() {
    let name = null;
    Routes.map((route) => {
      if (route.path === this.props.location.pathname) {
        name = route.name;
      }
      return null;
    });
    return name;
  }

  manageDrawer() {
    if (window.innerWidth < 993) {
      this.setState({ isOpen: false });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.manageDrawer);

    let selection = JSON.parse(localStorage.getItem('chosenRig'));
    let username = localStorage.getItem('user');
    let userEmail = localStorage.getItem('email');
    let profilePicture = localStorage.getItem('picture');

    if (selection === undefined || selection === null) {
       selection = this.props.rigs.rigs[0];
    }
    this.setState({
	chosenRig: selection,
        name: username,
	picture: profilePicture,
	email: userEmail,
	});
  }

  openFileUploadModal() {
    this.fileUploadModalRef.current.openModal();
  }

  getDrawer() {
    return (
      <React.Fragment>
        <img id="logo" src={Logo} alt="ensign-logo" />
        <Divider />
        <List>
          {Routes.map((route) => {
            return (
              <ListItem
                button
                key={route.name}
                component={NavLink}
                to={route.path}
                onClick={this.toggleDrawer}
              >
                {route.icon ? <ListItemIcon>{route.icon}</ListItemIcon> : null}
                <ListItemText primary={route.name} />
              </ListItem>
            );
          })}
        </List>
        <Divider />

        {process.env.REACT_APP_USE_AUTH === 'true' ? (
          <ListItem button key={'Logout'} onClick={this.logout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        ) : null}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={this.toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h5" color="inherit" id="header-route-name">
              {this.getRouteName()}
            </Typography>

            <Tooltip title="Rig Selector" placement="top">
              <div style={{ width: '100px', color: 'black' }}>
                <Select
                  value={this.state.chosenRig}
                  onChange={this.changeRig}
                  options={this.props.rigs.valid === true ? this.props.rigs.rigs: null}
                />
              </div>
            </Tooltip>

            <Tooltip title="Change Color Theme" placement="top">
              <IconButton
                color="inherit"
                aria-label="Change Color Theme Toggle"
                onClick={this.props.onChangeTheme}
              >
                <ColorLensIcon />
              </IconButton>
            </Tooltip>
	    <AutoTunerDialog />
	    <HistoricalViewDialog />
	    <UserConfigDialog />
            <Tooltip placement="Bottom"
	             title={
		         <React.Fragment>
                             <Typography color="inherit">{this.state.name}</Typography>
                             {this.state.email}
                         </React.Fragment>
                     }
            >
    	        <Avatar alt={this.state.name} src={this.state.picture}/>
            </Tooltip>
          </Toolbar>
          <FileUploadModalContainer ref={this.fileUploadModalRef} />
        </AppBar>

        <nav>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={this.state.isOpen}
              onClose={this.toggleDrawer}
            >
              {this.getDrawer()}
            </Drawer>
          </Hidden>
        </nav>
	{this.props.rigs.valid === true && typeof this.state.chosenRig !== 'undefined' ? (	
	<SimulatorRemote display={this.state.chosenRig.label === 'Simulator'} drilling={this.props.drilling} socket={this.props.socket}/>
	) : null }
      </React.Fragment>
    );
  }
}

export default AppBarAndDrawer;
