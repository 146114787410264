import { connect } from 'react-redux';
import HoistStateView from './hoistStateView';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    socket: state.connection.socket,
    target:  state.hoistState.targetVelocity,
    actual: state.hoistState.actualVelocity,
    min: state.hoistState.maxHoistVelocity,
    max: state.hoistState.maxLowerVelocity,
  };
};

const HoistStateViewContainer = connect(
  mapStateToProps,
)(HoistStateView);

export default HoistStateViewContainer;
