import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_ROTATE_REQUEST,
  SOCKET_ON_HISTORICAL_DATA,
} from '../actions/data';

// lastRotateRequestData: {
//   estimatedDuration: {
//     nanosec: 0,
//     sec: 0  ,
//   },
//   id: "c6a256a7-9874-4549-929d-3741985a61e4",
//   objectiveId: "96876483-1e39-4cba-9e5e-f271f0a97c11",
//   priority: 0,
//   targetRate: 3.1415926535897927,
//   timeNeeded:{
//     nanosec: 0,
//     sec: 0  ,
//   },
// }

export default function rotateRequestData(state, action) {
  if (typeof state === 'undefined') {
      return {
	  validData: false,
	  targetRate: 0,
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
    case SOCKET_ON_ROTATE_REQUEST:
      newState.targetRate = action.rotateRequest.targetRate;
      newState.validData = true;
      return newState;
    case SOCKET_ON_HISTORICAL_DATA:
      const lastRotateRequestData = action.historicalData.lastRotateRequestData;
      if (Object.keys(lastRotateRequestData).length !== 0 && lastRotateRequestData.constructor === Object) {
        newState.targetRate = lastRotateRequestData.targetRate;
      } else {
        newState.targetRate = -9999.25;
      }
      return newState;
    default:
      return state;
  }
}
