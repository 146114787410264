import React from 'react';
import Card from '@material-ui/core/Card';
import LinearProgress  from '@material-ui/core/LinearProgress';
import DownloadList from './downloadList';
import EnhancedTable from './EnhancedTable';
import Typography from '@material-ui/core/Typography';
import './historicalView.css';

export default class HistoricalView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
	  downloadData: false,
	  downloadFile: null,
	  fetchingObjectives: true,
	  selectedObjectives: [],
	  page: 0,
	  rowsPerPage: 10,
      };

    this.registerListeners = this.registerListeners.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getHistoricalObjectives = this.getHistoricalObjectives.bind(this);
    this.getHistoricalData = this.getHistoricalData.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);
  }

  closeModal() {
    this.setState({ downloadData: false });
  }

  componentDidMount()
  {
    this.registerListeners();
    this.getHistoricalObjectives();
  }

  componentDidUnmount() {
        this.props.stopSocketListeners();
	this.setState({
	    fetchingObjectives: false,
	    downloadFile: null,
	});
  }

  componentDidUpdate(prevProps, prevState) {
      if ((this.props.validObjectives.length === 0) &&
      	  (prevProps.validObjectives.length !== 0)) {
	      this.getHistoricalObjectives();
      }
  };

  onClickDownload(selected) {
    this.setState({
	downloadData: true,
        selectedObjectives: selected,
    });
  }

  getHistoricalObjectives() {
    if ((this.props.connection.connected) && (this.props.validObjectives.length === 0)) {
      this.props.emptyValidObjectives();
      this.props.connection.socket.emit('getValidObjectives');
      this.setState({
        fetchingObjectives: true,
        downloadFile: null,
      });
    } else if (!this.props.connection.connected) {
      let that = this;
      console.log('Socket not connected');
      setTimeout(function () {
        that.getHistoricalObjectives();
      }, 100);
    }
  }

  getHistoricalData(row) {
    if (this.props.connection.connected) {
    console.log("Getting historical Data", row.id);
      this.props.connection.socket.emit('getObjectiveData', row.id);
      this.setState({
        fetchingObjectives: false,
        downloadFile: row.id,
       });
    } else {
      let that = this;
      console.log('Socket not connected');
      setTimeout(function () {
        that.getHistoricalData();
      }, 100);
    }
  }

  registerListeners() {
    if (this.props.connection.connected) {
      this.props.watchObjectiveData(this.props.connection.socket);
     } else {
      let that = this;
      console.log('Socket not connected');
      setTimeout(function () {
        that.registerListeners();
      }, 100
    )};
  }
  
  render() {
      if (this.state.fetchingObjectives && this.props.numObjectives === -1) {
          return (
              <React.Fragment>
	          <Typography align="center">Fetching Historical Objectives</Typography>
	          <LinearProgress />
              </React.Fragment>
          )
      };

      if (this.state.downloadData === true) {
          return (
              <DownloadList objectives={this.state.selectedObjectives} getHistoricalData={this.getHistoricalData} historicalData={this.props.objectiveData} closeModal={this.closeModal}/>
          )
      };

      return (
          <Card>
	      <EnhancedTable rows={this.props.validObjectives} downloadEvent={this.onClickDownload} />
	  </Card>
      )
  }
}
