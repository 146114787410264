import React from "react"
import { arc } from "d3-shape"
import { scaleLinear } from "d3-scale"
import { format } from "d3-format"

export default function Gauge(props) {

  const backgroundArc = arc()
    .innerRadius(0.80)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(.5)
    ()

  const percentScale = scaleLinear()
    .domain([props.min, props.max])
    .range([0, 1])
  const percent = percentScale(props.value)

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true)

  const angle = angleScale(percent)

  const filledArc = arc()
    .innerRadius(0.80)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(angle)
    .cornerRadius(.5)
    ()

  const colorScale = scaleLinear()
    .domain([0, 1])
    .range(["#ccffcd", "#02e606"])

  const gradientSteps = colorScale.ticks(10).map(shade => colorScale(shade))

  return (
    <div
      style={{
        textAlign: "center",
      }}>
      <svg style={{overflow: "visible"}}
        width="9em"
        viewBox={[
          -1, -1,
          2, 1,
        ].join(" ")}>
        <defs>
          <linearGradient
            id="Gauge__gradient"
            gradientUnits="userSpaceOnUse"
            x1="-1"
            x2="1"
            y2="0">
            {gradientSteps.map((color, index) => (
              <stop
                key={color}
                stopColor={color}
                offset={`${
                  index
                  / (gradientSteps.length - 1)
                }`}
              />
            ))}
          </linearGradient>
        </defs>
        <path
          d={backgroundArc}
          fill="#dbdbe7"
        />
        <path
          d={filledArc}
          fill="url(#Gauge__gradient)"
        />
        <line
          y1="-1"
          y2="-0.65"
          stroke="white"
          strokeWidth="0.027"
        />
      </svg>

      <div style={{
        marginTop: "0.1em",
        fontSize: "2em",
        lineHeight: "1em",
        fontWeight: "400",
        fontFeatureSettings: "'zero', 'tnum' 1",
      }}>
        { format(",")(props.value) }
      </div>

      {!!props.label && (
        <div style={{
          color: "#8b8ba7",
          marginTop: "0.6em",
          fontSize: "1.0em",
          lineHeight: "1.3em",
          fontWeight: "400",
        }}>
          { props.label }
        </div>
      )}

      {!!props.units && (
        <div style={{
          color: "#8b8ba7",
          lineHeight: "1.3em",
          fontWeight: "200",
        }}>
          { props.units }
        </div>
      )}
    </div>
  )
}

/*const getCoordsOnArc = (angle, offset=10) => [
  Math.cos(angle - (Math.PI / 2)) * offset,
  Math.sin(angle - (Math.PI / 2)) * offset,
]*/
