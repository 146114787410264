import { connect } from 'react-redux';
import SimpleSnackbar from './simpleSnackbar';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    message: ownProps.message,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {};
};

const SimpleSnackbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SimpleSnackbar);

export default SimpleSnackbarContainer;
