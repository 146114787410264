import deepmerge from '../../helpers/deepmerge';
import {
  SET_DISPLAY_TRENDS
} from '../actions/data';


export default function displayTrends(state, action) {
  if (typeof state === 'undefined') {
      return {
	  "circulate": {
	      display: false
	  },
	  "hoist": {
	      display: false
	  },
	  "rotate": {
	      display: false
	  },
	  "rop": {
	      display: false
	  },
	  "wob": {
	      display: false
	  },
	  "diffp": {
	      display: false
	  },
	  "difft": {
	      display: false
	  },
	  "mse": {
	      display: false
	  },
	  "bitwear": {
	      display: false
	  },
	  "power": {
	      display: false
	  },
    };
  }


  switch (action.type) {
      case SET_DISPLAY_TRENDS:
          const newState = deepmerge({}, state);
          newState[action.trend] = newState[action.trend] || {};
          newState[action.trend].display = action.display;
          return newState;
      default:
          return state;
  }
}
