import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { theme } from './theme';
import LayoutContainer from './components/Layout/layoutContainer';

import store from './redux/store';

require('dotenv').config();

const hist = createBrowserHistory();

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isThemeDark: true,
    };

    this.onChangeTheme = this.onChangeTheme.bind(this);
  }

  onChangeTheme = () => {
    this.setState({
      isThemeDark: !this.state.isThemeDark
    })
  }

  render() {
     const { isThemeDark } = this.state;
     return (
       <MuiThemeProvider theme={theme(isThemeDark)}>
         <Switch>
           <Route
              path="/"
              render={(props) => <LayoutContainer {...props} onChangeTheme={this.onChangeTheme} />}
              />
         </Switch>
       </MuiThemeProvider>
     );
  }
}

const router = (
  <Router history={hist}>
    <Provider store={store}>
      <AppRouter />
    </Provider>
  </Router>
);

ReactDOM.render(router, document.getElementById("root"));
