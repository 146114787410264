import { connect } from 'react-redux';
import DrillingCalibrationApplication from './drillingCalibrationCard';

import {
  newDrillingCalibrationConfiguration,
} from '../../redux/actions/data';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
      drilling: state.rigObjective.objective === 4,
      socket: state.connection.socket,
      request: state.drillingCalibrationRequest,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
  };
};

const DrillingCalibrationApplicationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DrillingCalibrationApplication);

export default DrillingCalibrationApplicationContainer;
