import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_DRILLING_PERFORMANCE,
} from '../actions/data';

export default function drillingPerformance(state, action) {
  if (typeof state === 'undefined') {
    return {
	limiterSwapThisScan: false,
	limiterSwapsThisWindows: false,
	ropLimitingPercent: 0,
	wobLimitingPercent: 0,
	dpLimitingPercent: 0,
	torqueLimitingPercent: 0,
	noAxisLimitingPercent: 0,
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
  case SOCKET_ON_DRILLING_PERFORMANCE:
      newState.limiterSwapThisScan = action.drillingPerformance.limiterSwapThisScan;
      newState.limiterSwapsThisWindows = action.drillingPerformance.limiterSwapsThisWindows;
      newState.ropLimitingPercent = action.drillingPerformance.ropLimitingPercent;
      newState.wobLimitingPercent = action.drillingPerformance.wobLimitingPercent;
      newState.dpLimitingPercent = action.drillingPerformance.dpLimitingPercent;
      newState.torqueLimitingPercent = action.drillingPerformance.torqueLimitingPercent;
      newState.noAxisLimitingPercen = action.drillingPerformance.noAxisLimitingPercen;
      return newState;
    default:
      return state;
  }
}


