import React from 'react';
import OverviewViewContainer from "./components/Overview/overviewViewContainer";
import ResourcesViewContainer from "./components/Resources/resourcesViewContainer";
import WellPlanViewContainer from "./components/WellPlan/wellPlanViewContainer";

import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeIcon from '@material-ui/icons/Home';
import ResourcesIcon from '@material-ui/icons/Memory';

const routes = [
  { path: "/", name: "Overview", component: OverviewViewContainer, exact: true, icon: <HomeIcon /> },
  { path: "/resources", name: "Resources", component: ResourcesViewContainer, icon: <ResourcesIcon /> },
  { path: "/wellPlan", name: "Well Plan", component: WellPlanViewContainer, icon: <AssignmentIcon /> },
];

export default routes;
