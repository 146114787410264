import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_EMIT_ROPTIMIZER_CONFIGURATION_REQUEST,
  SOCKET_ON_ROPTIMIZER_CONFIGURATION,
  SOCKET_ON_HISTORICAL_DATA,
} from '../actions/data';

export default function roptimizerConfiguration(state, action) {
  if (typeof state === 'undefined') {
    return {
      steadyStateWindow: 0,
      steadyStateMin: 0,
      rpmStepSize: 0,
      ropStepSize: 0,
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
    case SOCKET_ON_ROPTIMIZER_CONFIGURATION:
      newState.steadyStateWindow = action.roptimizerData.steadyStateWindow;
      newState.steadyStateMin = action.roptimizerData.steadyStateMin;
      newState.rpmStepSize = action.roptimizerData.rpmStepSize;
      newState.ropStepSize = action.roptimizerData.ropStepSize;

      return newState;
    case SOCKET_EMIT_ROPTIMIZER_CONFIGURATION_REQUEST:
      return newState;
    case SOCKET_ON_HISTORICAL_DATA:
      const lastRoptimizerConfiguration = action.historicalData.lastRoptimizerConfiguration;
      if (Object.keys(lastRoptimizerConfiguration).length !== 0 && lastRoptimizerConfiguration.constructor === Object) {
        newState.steadyStateWindow = lastRoptimizerConfiguration.steadyStateWindow;
        newState.steadyStateMin = lastRoptimizerConfiguration.steadyStateMin;
        newState.rpmStepSize = lastRoptimizerConfiguration.rpmStepSize;
        newState.ropStepSize = lastRoptimizerConfiguration.ropStepSize;
      } else {
        newState.steadyStateWindow = 0;
        newState.steadyStateMin = 0;
        newState.rpmStepSize = 0;
        newState.ropStepSize = 0;
      }
      return newState;
    default:
      return state;
  }
}
