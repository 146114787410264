import deepmerge from '../../helpers/deepmerge';
import {
    SOCKET_ON_CIRCULATE_STATE,
} from '../actions/data';

export default function circulateState(state, action) {
  if (typeof state === 'undefined') {
      return {
	  valid: false,
	  target: 0,
	  actual: 0,
	  min: 0,
	  max: 0,
	  standpipePressure: 0,
	  data: [],
      };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
  case SOCKET_ON_CIRCULATE_STATE:
      if (Array.isArray(action.circulateState) === false) {
	  newState.valid = true;
	  newState.actual = action.circulateState.actualFlowRate;
	  newState.target = action.circulateState.targetFlowRate;
	  newState.min = action.circulateState.minFlowRate;
	  newState.max = action.circulateState.maxFlowRate;
	  newState.standpipePressure = action.circulateState.actualStandpipePressure;
      }
      newState.data = newState.data.concat(action.circulateState);
	  
      if (newState.data.length > process.env.REACT_APP_DATA_POINT_LIMIT) {
          newState.data = newState.data.slice(newState.data.length - process.env.REACT_APP_DATA_POINT_LIMIT);
      }
      return newState;
    default:
      return state;
  }
}
