import deepmerge from '../../helpers/deepmerge';

import {
  LOGIN_PROCESSING,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
} from '../actions/auth';

const defaultState = {
    user: null,
    role: null,
    processing: null,
    err: null,
    loggedIn: false,
};

export default function auth(state, action) {
  if (typeof state === 'undefined') {
    return defaultState;
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
    case LOGIN_SUCCESS:
      newState.user = action.user[0].email;
      newState.role = action.user[0].role;
      newState.processing = false;
      newState.loggedIn = true;
      return newState;

    case LOGIN_PROCESSING:
      newState.processing = action.processing;
      return newState;

    case LOGOUT_REQUEST:
      newState.processing = true;
      return newState

    case LOGOUT_SUCCESS:
      newState.user = null;
      newState.processing = false;
      newState.loggedIn = false;
      return newState;

    default:
      return newState;
  }
}
