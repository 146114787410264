import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Clock from '../Common/clock';
import Grid from '@material-ui/core/Grid';
import Moment from 'react-moment';
import Typography from '@material-ui/core/Typography';
import './objective.css';
import { length } from "units-converter";
import { format } from "d3-format"

class ObjectiveView extends React.Component {

  componentWillUnmount() {
    if (this.props.connected === false) {
      this.props.socket.off("rigObjective");
      }
  }

  render() {
    const wellboreData = this.props;
    const lengthUom = localStorage.getItem("length");
    var actualPosition = length(wellboreData.actualPosition).from('m').to(lengthUom);

    return (
        <React.Fragment>
	<Grid item xs={12} sm={6} md={3} lg={3}>
            <Card classes={{ root: 'objective-card' }} className = "objective-card" style={{ height: "200px" }}>
                <CardHeader className="objective-label" title="Objective"
 	            titleTypographyProps={{ align: "center", variant: "h6"}}  />
                <CardContent>
		<Grid item container xs={12}>
		  <Grid item xs={6}>
     	            <Typography className="objective-label" align="right" >Activity:</Typography>
	          </Grid>
		  <Grid item xs={6}>
        	    <Typography className="objective-value" align="right">{this.props.rigObjective.objectiveStr}</Typography>
	          </Grid>
		  <Grid item xs={6}>
		    <Typography className="objective-label" align="right">Start Time:</Typography>
	          </Grid>
  		  <Grid item xs={6}>
		    <Typography className="objective-value"  align="right">
		  	  <Moment date={this.props.rigObjective.timestamp} format="HH:mm:ss" />
	            </Typography>
                  </Grid>
		  <Grid item xs={6}>
		    <Typography className="objective-label" align="right">Duration:</Typography>
	          </Grid>
		  <Grid item xs={6}>
   	            <Typography className="objective-value"  align="right"><Clock startTime={this.props.rigObjective.timestamp}/></Typography>
	          </Grid>
		  {((this.props.drilling === true) ||
		    (this.props.sliding === true)) ? (
		    <Grid item xs={6}>
		      <Typography className="objective-label" align="right">% Completed:</Typography>
	            </Grid>
	           ): null }
		  {((this.props.drilling === true) ||
		    (this.props.sliding === true)) ? (
		    <Grid item xs={6}>
   	              <Typography className="objective-value" align="right">{ format("8,.1%")(((actualPosition.value / 82) - 1) * -1)}</Typography>
	            </Grid>
	           ): null }
	        </Grid>
                </CardContent>
            </Card>
        </Grid>
        </React.Fragment>
    );
  }
}

export default ObjectiveView;
