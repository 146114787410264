import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import DisplayValue from '../Common/displayValue';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { pressure } from "units-converter";
import { force } from "units-converter";

class PressureView extends React.Component {

  componentDidMount() {
    if (this.props.socket) {
    }
  }
  
  componentWillUnmount() {
    if (this.props.socket) {
      }
  }

  render() {
    const forceUom = localStorage.getItem("force");
    const pressureUom = localStorage.getItem("pressure");
    var hookload = force(this.props.hookload).from('N').to(forceUom);
    var standpipePressure = pressure(this.props.standpipePressure).from('Pa').to(pressureUom);

    return (
	<Grid item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ height: "200px" }}>
              <CardHeader title="Wellbore Integrity"
 	                  titleTypographyProps={{ align: "center", variant: "h6"}}  />
                <CardContent>
		<Grid item container xs={12}>
		  <Grid item xs={7}>
   	            <Typography align="right">Standpipe Pressure:</Typography>
		  </Grid>
		  <Grid item xs={5}>
		    <DisplayValue value={standpipePressure.value} uom={standpipePressure.unit} />
		  </Grid>
		  <Grid item xs={7}>
   	            <Typography align="right">Hookload:</Typography>
		  </Grid>
		  <Grid item xs={5}>
		    <DisplayValue value={hookload.value} uom={hookload.unit} />
		  </Grid>
		  <Grid item xs={12}>
   	            <Typography></Typography>
		  </Grid>
	        </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
  }
}

export default PressureView;
