import { connect } from 'react-redux';
import HistoricalView from './historicalView';
import {
  eraseObjectiveData,
  eraseValidObjectives,
  newObjectiveData,
} from '../../redux/actions/data';

const mapStateToProps = function mapStateToProps(state, ownProps) {
    return {
	connection: state.connection,
	validObjectives: state.objectiveData.validObjectives,
	numObjectives: state.objectiveData.numObjectives,
	objectiveData: state.objectiveData.objectiveData,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
	emptyObjectiveData() {
	    dispatch(eraseObjectiveData());
	},
	emptyValidObjectives() {
	    dispatch(eraseValidObjectives());
	},
	watchObjectiveData(socket) {
	    socket.on('objectiveData', (objectiveData) => {
		dispatch(newObjectiveData(objectiveData));
	    });
	},
	stopSocketListeners(socket) {
	    socket.removeListener('objectiveData');
	},
    };
};

const HistoricalViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HistoricalView);

export default HistoricalViewContainer;
