import { connect } from 'react-redux';
import { angularVelocity } from "units-converter";
import { velocity } from "units-converter";
import { force } from "units-converter";
import { torque } from "units-converter";
import { pressure } from "units-converter";
import DygraphLineGraph from './dygraphLineGraph';

const mapStateToProps = function mapStateToProps(state, ownProps) {
    let params;
    const velocityUom = localStorage.getItem("velocity");
    const forceUom = localStorage.getItem("force");
    const pressureUom = localStorage.getItem("pressure");
    const torqueUom = localStorage.getItem("torque");
    const angularVelocityUom = localStorage.getItem("angularVelocity");

    if (ownProps.type === 'ROP') {
	const ropData = state.drillState.rop.data.map(dataObj => { 
	    var actualRate = velocity(dataObj.actual).from('m/s').to(velocityUom);
	    var targetRate = velocity(dataObj.target).from('m/s').to(velocityUom);
	    return {
		millisecondsEpochUTC: dataObj.millisecondsEpochUTC,
		actual: actualRate.value,
		target: targetRate.value,
	    };
	});
	params = {
	    title: 'Rate of Penetration',
	    dataType: 'drillStateLineGraph',
	    data: ropData,
	    min: velocity(state.limits.ropMin).from('m/s').to(velocityUom),
	    max: velocity(state.limits.ropMax).from('m/s').to(velocityUom),
	};
    } else if (ownProps.type === 'WOB') {
	const wobData = state.drillState.wob.data.map(dataObj => { 
	    var actualRate = force(dataObj.actual).from('N').to(forceUom);
	    var targetRate = force(dataObj.target).from('N').to(forceUom);
	    return {
		millisecondsEpochUTC: dataObj.millisecondsEpochUTC,
		actual: actualRate.value,
		target: targetRate.value,
	    };
	});
	params = {
	    title: 'Weight On Bit',
	    dataType: 'drillStateLineGraph',
	    data: wobData,
	    min: force(state.limits.wobMin).from('N').to(forceUom),
	    max: force(state.limits.wobMax).from('N').to(forceUom),
	};
    } else if (ownProps.type === 'Differential Pressure') {
	const diffpData = state.drillState.diffp.data.map(dataObj => { 
	    var actualRate = pressure(dataObj.actual).from('Pa').to(pressureUom);
	    var targetRate = pressure(dataObj.target).from('Pa').to(pressureUom);
	    return {
		millisecondsEpochUTC: dataObj.millisecondsEpochUTC,
		actual: actualRate.value,
		target: targetRate.value,
	    };
	});
	params = {
	    title: 'Differential Pressure',
	    dataType: 'drillStateLineGraph',
	    data: diffpData,
	    min: pressure(state.limits.diffPMin).from('Pa').to(pressureUom),
	    max: pressure(state.limits.diffPMax).from('Pa').to(pressureUom),
	};
    } else if (ownProps.type === 'Torque') {
	const torqueData = state.drillState.torque.data.map(dataObj => { 
	    var actualRate = torque(dataObj.actual).from('Nm').to(torqueUom);
	    var targetRate = torque(dataObj.target).from('Nm').to(torqueUom);
	    return {
		millisecondsEpochUTC: dataObj.millisecondsEpochUTC,
		actual: actualRate.value,
		target: targetRate.value,
	    };
	});
	params = {
	    title: 'Torque',
	    dataType: 'drillStateLineGraph',
	    data: torqueData,
	    min: torque(state.limits.torqueMin).from('Nm').to(torqueUom),
	    max: torque(state.limits.torqueMax).from('Nm').to(torqueUom),
	};
    } else if (ownProps.type === 'RPM') {
	const rotateData = state.rotateState.data.map(dataObj => { 
	    var actualRate = angularVelocity(dataObj.actualRate).from('rad/s').to(angularVelocityUom);
	    var targetRate = angularVelocity(dataObj.targetRate).from('rad/s').to(angularVelocityUom);
	    return {
		millisecondsEpochUTC: dataObj.millisecondsEpochUTC,
		actual: actualRate.value,
		target: targetRate.value,
	    };
	});
	params = {
	    title: 'RPM Actual',
	    dataType: 'rpmLineGraph',
	    data: rotateData,
	    min: angularVelocity(state.limits.rotateMin).from('rad/s').to(angularVelocityUom),
	    max: angularVelocity(state.limits.rotateMax).from('rad/s').to(angularVelocityUom),
	};
    } else if (ownProps.type === 'Depth') {
	params = {
	    title: 'Depths',
	    data: state.wellboreStateData.data,
	    dataType: 'withDateAndDepth',
	};
    } else if (ownProps.type === 'CPU') {
	params = {
	    title: 'CPU ' + ownProps.process,
	    data: state.resourcesCpu[ownProps.process].data,
	    dataType: 'dateActualMaxMinCPU',
	};
    } else if (ownProps.type === 'Threads') {
	params = {
	    title: 'Threads ' + ownProps.process,
	    data: state.resourcesThreads[ownProps.process].data,
	    dataType: 'dateActualMaxMinThreads',
	};
    } else if (ownProps.type === 'MSE') {
	params = {
	    min: 0,
	    max: 200000,
	    title: 'MSE',
	    data: state.roptimizerSteadyState.mseData,
	    dataType: 'dateMSEMaxMin',
	};
    } else if (ownProps.type === 'Bit Wear') {
	params = {
	    min: 0,
	    max: 10000,
	    title: 'Bit Wear',
	    data: state.roptimizerSteadyState.bitWearData,
	    dataType: 'dateBitWearMaxMin',
	};
    } else if (ownProps.type === 'Power') {
	params = {
	    min: 0,
	    max: 2000000,
	    title: 'Power',
	    data: state.roptimizerSteadyState.powerData,
	    dataType: 'datePowerMaxMin',
	};
    }
    params.drilling = state.rigObjective.objective === 4;
    return params;
};

const mapDispatchToProps = function (dispatch) {
    return {};
};

const DygraphLineGraphContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DygraphLineGraph);

export default DygraphLineGraphContainer;
