import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_CONNECTED,
  SOCKET_CONNECT_ERROR,
  SOCKET_RECONNECTED,
  SOCKET_RECONNECTING,
  SOCKET_DISCONNECTED,
  SOCKET_CLOSE_CONNECTION,
} from '../actions/data';

export default function connection(state, action) {
  if (typeof state === 'undefined') {
    return {
	socket: null,
	connected: false,
	reconnectionAttempt: 0,
	disconnetions: 0,
	failedConnections: 0,
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
    case SOCKET_CONNECTED:
      console.log("REDUX Reducer: Socket Connected");
      newState.socket = action.socket;
      newState.connected = action.socket.connected;
      return newState;
  case SOCKET_CONNECT_ERROR:
      const connect_error = newState.failedConnections;
      console.log("REDUX Reducer: Socket Connection Error");
      newState.socket = action.socket;
      newState.connected = action.socket.connected;
      newState.failedConnections = connect_error + 1;
      return newState;
    case SOCKET_RECONNECTED:
      console.log("REDUX Reducer: Socket Reconnected");
      newState.socket = action.socket;
      return newState;
    case SOCKET_RECONNECTING:
      console.log("REDUX Reducer: Socket Reconnecting");
      newState.connected = false;
      newState.reconnectionAttempt = newState.reconnectionAttempt + 1;
      return newState;
    case SOCKET_DISCONNECTED:
      console.log("REDUX Reducer: Socket Disconnected");
      newState.connected = action.socket.connected;
      newState.disconnetions = newState.disconnetions + 1;
      return newState;
    case SOCKET_CLOSE_CONNECTION:
      console.log("REDUX Reducer: Close Socket");
      newState.socket = {};
      newState.connected = false;
      return newState;
    default:
      return state;
  }
}
