import React from 'react';
import AutoTunerOverview from './AutoTunerOverview';
import ModelState from './ModelState';
import AutoTunerTuning from './AutoTunerTuning';
import Grid from '@material-ui/core/Grid';

import './AutoTunerModal.css';

class AutoTunerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitAutoTunerConfigurationRequest = this.submitAutoTunerConfigurationRequest.bind(
      this,
    );
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  submitAutoTunerConfigurationRequest(requestType, configurationRequestData) {
    this.props.submitConfigurationRequest(
      this.props.socket,
      requestType,
      configurationRequestData,
    );
  }

  render() {
    return (
        <React.Fragment>
          <Grid item xs={12} container spacing={2} justify="space-evenly">
              <ModelState
		modelState={this.props.modelState}
            	configurationRequest={this.submitAutoTunerConfigurationRequest}
            		closeModal={this.closeModal}        />
              <AutoTunerOverview
                  autoTunerConfiguration={this.props.autoTunerConfiguration}
                  drillingCalibration={this.props.drillingCalibration}
              />
	  </Grid>
          <Grid item xs={12} container spacing={2} justify="space-evenly">
	      {Object.values(this.props.autoTunerConfiguration).map((axis) => (
	        <AutoTunerTuning
			autoTunerConfiguration={axis}
			configurationRequest={this.submitAutoTunerConfigurationRequest}
              	closeModal={this.props.closeModal} />
		))}
	  </Grid>
       </React.Fragment>
    );
  }
}

export default AutoTunerModal;
