import { connect } from 'react-redux';
import DrillLimitsView from './drillLimitsView';

const mapStateToProps = function mapStateToProps(state, ownProps) {
    return {
        loggedIn: state.auth.loggedIn,
        limits: state.limits,
        socket: state.connection.socket,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {};
};

const DrillLimitsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
   { forwardRef: true },
)(DrillLimitsView);

export default DrillLimitsContainer;
