import { connect } from 'react-redux';
import ResourcesView from './resourcesView';

const mapStateToProps = function mapStateToProps(state) {
  return {
      connected: state.connection.connected,
      resources: state.resources,
  };
};

const ResourcesViewContainer = connect(
  mapStateToProps,
)(ResourcesView);

export default ResourcesViewContainer;
