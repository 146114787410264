import deepmerge from '../../helpers/deepmerge';
import { SOCKET_ON_DRILLING_CALIBRATION_REQUEST } from '../actions/autotunerData';

export default function drillingCalibrationRequest(state, action) {
  if (typeof state === 'undefined') {
      return {
	  timestamp: { sec: 0, nanosec: 0 },
	  wobProportional: 0.0,
	  wobIntegral: 0.0,
	  differentialPressureProportional: 0.0,
	  differentialPressureIntegral: 0.0,
	  torqueProportional: 0.0,
	  torqueIntegral: 0.0,
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
    case SOCKET_ON_DRILLING_CALIBRATION_REQUEST:
      newState.timestamp = action.drillingCalibrationRequest.timestamp;
      newState.wobProportional = action.drillingCalibrationRequest.wobProportional;
      newState.wobIntegral = action.drillingCalibrationRequest.wobIntegral;
      newState.differentialPressureProportional = action.drillingCalibrationRequest.differentialPressureProportional;
      newState.differentialPressureIntegral = action.drillingCalibrationRequest.differentialPressureIntegral;
      newState.torqueProportional = action.drillingCalibrationRequest.torqueProportional;
      newState.torqueIntegral = action.drillingCalibrationRequest.torqueIntegral;
      return newState;
    default:
      return state;
  }
}
