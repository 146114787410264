import { createMuiTheme } from '@material-ui/core/styles';

export const theme = (isThemeDark) => createMuiTheme({
  palette: {
    type: isThemeDark ? 'dark' : 'light',
    primary: { main: "#232d62" },
    secondary: { main: "#e0282e" },
    error: { main: "#d2ac23" },
  },
  typography: { useNextVariants: true },
});
