import { connect } from 'react-redux';
import CirculateStateView from './circulateStateView';
import { setDisplayTrends } from '../../redux/actions/data';

const mapStateToProps = function mapStateToProps(state) {
    return {
	socket: state.connection.socket,
	validData: state.circulateState.valid,
	target: state.circulateState.target,
	actual: state.circulateState.actual,
	min: state.circulateState.min,
	max: state.circulateState.max,
	displayTrend: state.displayTrends["circulate"].display,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
	toggleFlowTrend(trend, display) {
            dispatch(setDisplayTrends(trend, display));
	},
    };
};

const CirculateStateViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CirculateStateView);

export default CirculateStateViewContainer;
