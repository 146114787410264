import React from 'react';
import RealTimeDataCard from '../Common/realTimeDataCard';
import './circulateStateView.css';
import { volumeFlowRate } from "units-converter";

export default class CirculateStateView extends React.Component {
    constructor(props) {
        super(props);

        this.toggleFlowTrend = this.toggleFlowTrend.bind(this);
    }

    componentDidMount() {
        this.props.socket.emit('getCirculateStateHistory');
    }

    toggleFlowTrend(event, value) {
        this.props.toggleFlowTrend("circulate", !this.props.displayTrend);
    }

    render() {
        if (this.props.validData === false) {
            return (
                <React.Fragment/>
        )
    }

    const volumeFlowRateUom = localStorage.getItem("volumeFlowRate");
    var actual = volumeFlowRate(this.props.actual).from('m3/s').to(volumeFlowRateUom);
    var target = volumeFlowRate(this.props.target).from('m3/s').to(volumeFlowRateUom);
    var min = volumeFlowRate(this.props.min).from('m3/s').to(volumeFlowRateUom);
    var max = volumeFlowRate(this.props.max).from('m3/s').to(volumeFlowRateUom);

    return (
        <React.Fragment>
            <RealTimeDataCard title="Flow"
	                      target={Number.parseFloat(target.value).toFixed()}
			      value={Number.parseFloat(actual.value).toFixed()}
			      converter={volumeFlowRate}
			      min={Number.parseFloat(min.value).toFixed()}
			      max={Number.parseFloat(max.value).toFixed()}
   		    	      units={volumeFlowRateUom}
			      displayTrend={this.props.displayTrend}
                              toggleTrend={this.toggleFlowTrend} />
        </React.Fragment>
    );
  }
}

