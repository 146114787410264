import React from 'react';
import RealTimeDataCard from '../Common/realTimeDataCard';
import SteadyStateIndicatorSelectorContainer from '../RoptimizerApplication/steadyStateIndicatorSelectorContainer';
import './rotateStateView.css';
import { angularVelocity } from "units-converter";

export default class RotateStateView extends React.Component {
    constructor(props) {
        super(props);

      this.toggleRotateTrend = this.toggleRotateTrend.bind(this);
  }

  componentDidMount() {
      this.props.socket.emit('getRotateStateHistory');
  };

  toggleRotateTrend(event, value) {
      this.props.toggleRotateTrend("rotate", !this.props.displayTrend);
  }

  render() {
    if (this.props.validData === false) {
       return (
         <React.Fragment/>
     )}

    const angularVelocityUom = localStorage.getItem("angularVelocity");
    var actual = angularVelocity(this.props.actual).from('rad/s').to(angularVelocityUom);
    var target = angularVelocity(this.props.target).from('rad/s').to(angularVelocityUom);
    var min = angularVelocity(this.props.min).from('rad/s').to(angularVelocityUom);
    var max = angularVelocity(this.props.max).from('rad/s').to(angularVelocityUom);

    return (
        <React.Fragment>
	      <RealTimeDataCard title="Rotation"
	      		  	icon={<SteadyStateIndicatorSelectorContainer type="rpm" drilling={this.props.drilling} /> }
				target={Number.parseFloat(target.value).toFixed()}
			        value={Number.parseFloat(actual.value).toFixed()}
			        min={Number.parseFloat(min.value).toFixed()}
			        max={Number.parseFloat(max.value).toFixed()}
   		    	        units={angularVelocityUom}
			        displayTrend={this.props.displayTrend}
                                toggleTrend={this.toggleRotateTrend} />
        </React.Fragment>
    );
  }
}
