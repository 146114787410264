import deepmerge from '../../helpers/deepmerge';
import {
    SOCKET_ON_MODEL_STATE,
} from '../actions/data';

export default function modelStateData(state, action) {
  if (typeof state === 'undefined') {
      return {
          valid: false,
	  pipeInnerDiameter: 0,
	  pipeOuterDiameter: 0,
	  slopeFilter: 0,
	  tauMax: 0,
	  tauMin: 0,
	  tauMultiplier: 0,
	  maxDeviation: 0,
	  minInterval: 0,
	  tunerEnabled: false,
      };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
    case SOCKET_ON_MODEL_STATE:
      newState.pipeInnerDiameter = action.modelState.pipeInnerDiameter;
      newState.pipeOuterDiameter = action.modelState.pipeOuterDiameter;
      newState.slopeFilter = action.modelState.slopeFilter;
      newState.tauMax = action.modelState.tauMax;
      newState.tauMin = action.modelState.tauMin;
      newState.tauMultiplier = action.modelState.tauMultiplier;
      newState.maxDeviation = action.modelState.maxDeviation;
      newState.minInterval = action.modelState.minInterval;
      newState.tunerEnabled = action.modelState.tunerEnabled;
      return newState;
    default:
      return state;
  }
}
