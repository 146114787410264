import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_RESOURCES,
} from '../actions/data';

export default function resources(state, action) {
  if (typeof state === 'undefined') {
      return {
	  "hoist_nec" : {
	      alive: false,
	      appVersion: '0.0.0.0',
	      pid: -1,
	      upTime: -1,
	  },
	  "circulate_nec" :{
	      alive: false,
	      appVersion: '0.0.0.0',
	      pid: -1,
	      upTime: -1,
	  },
	  "rotate_nec" :{
	      alive: false,
	      appVersion: '0.0.0.0',
	      pid: -1,
	      upTime: -1,
	  },
	  "drilling_adept" :{
	      alive: false,
	      appVersion: '0.0.0.0',
	      pid: -1,
	      upTime: -1,
	  },
/*	  "AutoTuner.exe" :{
	      alive: false,
	      appVersion: '0.0.0.0',
	      pid: -1,
	      upTime: -1,
	  },*/
    };
  }

  switch (action.type) {
    case SOCKET_ON_RESOURCES:
      let newState = deepmerge({}, state);
      const processName = action.resources.processName;
      newState[processName] = newState[processName] || {};
      newState[processName].alive = action.resources.alive;
      newState[processName].pid = action.resources.pid;
      newState[processName].upTime = action.resources.upTime;
      newState[processName].cpuPercent = action.resources.cpuPercent;

      newState[processName].time = (action.resources.timestamp.sec * 1000 + action.resources.timestamp.nanosec / 1000000);
      newState[processName].timestamp = action.resources.timestamp;

      newState[processName].vmPeak = action.resources.vmPeak;
      newState[processName].vmSize = action.resources.vmSize;
      newState[processName].vmSwap = action.resources.vmSwap;
      newState[processName].vmMaxSwap = action.resources.vmMaxSwap;

      newState[processName].osName = action.resources.osName;
      newState[processName].appVersion = action.resources.appVersion;

      return newState;
    default:
      return state;
  }
}
