import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_RESOURCES,
  SOCKET_ON_HISTORICAL_DATA,
} from '../actions/data';

export default function resourcesCpu(state, action) {
  if (typeof state === 'undefined') {
    return {
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
    case SOCKET_ON_RESOURCES:
      const processName = action.resources.processName;
      newState[processName] = newState[processName] || { data: [] };

      if (newState[processName].data.length < 200) {
        newState[processName].data = newState[processName].data.concat([action.resources.cpuDataPoint]);
      } else {
        newState[processName].data.push(action.resources.cpuDataPoint);
        newState[processName].data = newState[processName].data.slice(1);
      }
      return newState;
    case SOCKET_ON_HISTORICAL_DATA:
      const historicalResourcesCPU = action.historicalData.historicalResourcesCPU;
      let processNames = Object.keys(historicalResourcesCPU);
      if (processNames.length !== 0 && historicalResourcesCPU.constructor === Object) {
        processNames.forEach(function(processName) {
          newState[processName] = newState[processName] || { data: [] };
          newState[processName].data = historicalResourcesCPU[processName];
        });
      }
      return newState;
    default:
      return state;
  }
}
