import React from 'react';

import SimpleSnackbar from '../SimpleSnackbar/simpleSnackbarContainer';

export default class ResourcesSnackbarManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snacks: [],
    };

    this.getInactiveProcesses = this.getInactiveProcesses.bind(this);
  }

  getInactiveProcesses() {
    let resources = this.props.resources;
    let processNames = Object.keys(resources);
    let inactiveProcesses = processNames.filter(processName => resources[processName].alive === false);

    return inactiveProcesses;
  }

  render() {
    let connected = this.props.connected;

    if (connected === false) {
      return null;
    } else {
      let inactiveProcesses = this.getInactiveProcesses();

      return (
        <React.Fragment>
        {(inactiveProcesses !== undefined && inactiveProcesses.length > 0) ?
                (<SimpleSnackbar message={'Following services are inactive: ' + inactiveProcesses.join(', ')} />)
		: null}
        </React.Fragment>
      );
    }
  }
}
