import { connect } from 'react-redux';
import OverviewView from './overviewView';

const mapStateToProps = function mapStateToProps(state) {
    return {
	connected: state.connection.connected,
	rigObjective: state.rigObjective,
	displayTrends:  state.displayTrends.historicalTrend,
	drilling: state.rigObjective.objective === 4,
	sliding: state.rigObjective.objective === 5,
	displayRotateTrend: state.displayTrends["rotate"].display,
	displayFlowTrend: state.displayTrends["circulate"].display,
	displayRopTrend: state.displayTrends["rop"].display,
	displayWobTrend: state.displayTrends["wob"].display,
	displayDiffpTrend: state.displayTrends["diffp"].display,
	displayTorqueTrend: state.displayTrends["difft"].display,
	displayMseTrend: state.displayTrends["mse"].display,
	displayPowerTrend: state.displayTrends["power"].display,
	displayBitwearTrend: state.displayTrends["bitwear"].display,
    };
};

const OverviewViewContainer = connect(
  mapStateToProps,
)(OverviewView);

export default OverviewViewContainer;
