import DrillingMetricsView from './drillingMetricsView';
import { newDrillingMetrics } from '../../redux/actions/data';
import { connect } from 'react-redux';

const mapStateToProps = function mapStateToProps(state) {
    return {
	socket: state.connection.socket,
	drillingMetrics: state.drillingMetrics,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
      watchDrillingMetrics(socket) {
	socket.on('drillingMetrics', (drillingMetrics) => {
        dispatch(newDrillingMetrics(drillingMetrics));
        });
      },
    };
};

const DrillingMetricsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DrillingMetricsView);

export default DrillingMetricsContainer;
