import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_DRILLING_METRICS,
} from '../actions/data';

export default function drillingMetrics(state, action) {
  if (typeof state === 'undefined') {
    return {
	valid: false,

	bitMse: 0,
	surfaceMse: 0,
	stickSlipSeverity: 0,
	depthOfCut: 0,
	history: [],
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
  case SOCKET_ON_DRILLING_METRICS:
      newState.valid = true;

      newState.surfaceMse = action.drillingMetrics.surfaceMse;
      newState.bitMse = action.drillingMetrics.bitMse;
      newState.stickSlipSeverity = action.drillingMetrics.stickSlipSeverity
      newState.depthOfCut = action.drillingMetrics.depthOfCut;

      newState.history = newState.history.concat(action.drillingMetrics);
      if (newState.history.length > process.env.REACT_APP_DATA_POINT_LIMIT) {
        newState.history = newState.history.slice(1);
      }
      return newState;
    default:
      return state;
  }
}
