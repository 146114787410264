import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { createInputWithTooltip } from '../Common/CreateInputsHelperMethods';

class AutoTunerOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      diffpProportional: props.drillingCalibration.differentialPressureProportional,
      diffpIntegral: props.drillingCalibration.differentialPressureIntegral,
      wobProportional: props.drillingCalibration.wobProportional,
      wobIntegral: props.drillingCalibration.wobIntegral,
      torqueProportional: props.drillingCalibration.torqueProportional,
      torqueIntegral: props.drillingCalibration.torqueIntegral,
    };

    this.renderDiffPTuning = this.renderDiffPTuning.bind(this);
    this.renderWOBTuning = this.renderWOBTuning.bind(this);
    this.renderTorqueTuning = this.renderTorqueTuning.bind(this);
    this.updateInput = this.updateInput.bind(this);
  }

  updateInput(name) {
    let that = this;
    return function (event) {
      that.setState({ [name]: event.target.value });
    };
  }

  renderDiffPTuning() {
    return (
      <React.Fragment>
        <Grid item>
          <Grid container justify="center" direction="column">
            <Grid item>
              <CardHeader subheader="Differential Pressure" />
            </Grid>
            {createInputWithTooltip(
              'differentialPressureProportional',
              'Proportional',
              this.props.drillingCalibration.differentialPressureProportional,
              this.state.diffpProportional,
	      this.updateInput,
            )}
            {createInputWithTooltip(
              'differentialPressureIntegral',
              'Integral',
              this.props.drillingCalibration.differentialPressureIntegral,
              this.state.diffpIntegral,
	      this.updateInput,
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  renderWOBTuning() {
    return (
      <React.Fragment>
        <Grid item>
          <Grid container justify="center" direction="column">
            <Grid item>
              <CardHeader subheader="Weight On Bit" />
            </Grid>
            {createInputWithTooltip(
              'wobProportional',
              'Proportional',
              this.props.drillingCalibration.wobProportional,
	      this.state.wobProportional,
	      this.updateInput,
            )}
            {createInputWithTooltip(
              'wobIntegral',
              'Integral',
              this.props.drillingCalibration.wobIntegral,
	      this.state.wobIntegral,
	      this.updateInput,
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  renderTorqueTuning() {
    return (
      <React.Fragment>
        <Grid item>
          <Grid container justify="center" direction="column">
            <Grid item>
              <CardHeader subheader="Torque" />
              {createInputWithTooltip(
                'torqueProportional',
                'Proportional',
                this.props.drillingCalibration.torqueProportional,
	        this.state.torqueProportional,
	        this.updateInput,
              )}
              {createInputWithTooltip(
                'torqueIntegral',
                'Integral',
                this.props.drillingCalibration.torqueIntegral,
	        this.state.torqueIntegral,
	        this.updateInput,
              )}
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Grid
        xs={6}
        id="modal-content-tab-content-at-overview"
        justify="center"
        spacing={2}
      >
        <Grid item>
          <Card className="configuration-request-card" variant="outlined">
            <CardHeader title="Drilling Calibration" titleTypographyProps={{ align: "center", variant: "subtitle1"}} />
            <CardContent>
              <Grid container spacing={2} justify="center">
                {this.renderDiffPTuning()}
                {this.renderWOBTuning()}
                {this.renderTorqueTuning()}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default AutoTunerOverview;
