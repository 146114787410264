import { connect } from 'react-redux';
import AutoTunerView from './AutoTunerView';
import {
  newDrillingCalibrationData,
} from '../../redux/actions/autotunerData';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    autoTunerConfiguration: state.autoTunerStateData,
    modelState: state.modelStateData,
    drillingCalibration: state.drillingCalibration,
    socket: state.connection.socket,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
      submitConfigurationRequest(socket, type, data) {
      socket.emit(type, data);
    },
  };
};

const AutoTunerViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(AutoTunerView);

export default AutoTunerViewContainer;
