import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_HOIST_STATE,
} from '../actions/data';


export default function hoistState(state, action) {
    if (typeof state === 'undefined') {
	return {
	    validData: false,
	    hookload: 0.0,
	    maxHookload: 0.0,
	    targetVelocity: 0.0,
	    actualVelocity: 0.0,
	    minVelocity: 0.0,
	    maxVelocity: 0.0,
	    targetPosition: 0.0,
	    actualPosition: 0.0,
	    minPosition: 0.0,
	    maxPosition: 0.0,
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
      case SOCKET_ON_HOIST_STATE:
          newState.validData = true;
          newState.hookload = action.hoistState.actualHookload;
          newState.maxHookload = action.hoistState.maxHookload;
          newState.targetVelocity = action.hoistState.targetVelocity;
          newState.actualVelocity = action.hoistState.actualVelocity;
          newState.minVelocity = action.hoistState.maxHoistVelocity;
          newState.maxVelocity = action.hoistState.maxLowerVelocity;
          newState.targetPosition = action.hoistState.targetPosition;
          newState.actualPosition = action.hoistState.actualPosition;
          newState.minPosition = action.hoistState.maxHoistVelocity;
          newState.maxPosition = action.hoistState.maxLowerVelocity;
          return newState;
    default:
      return state;
  }
}
