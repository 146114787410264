import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BarChartIcon from '@material-ui/icons/BarChart';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Gauge from '../Common/gauge';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from "d3-format"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function RealTimeDataCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
        <CardHeader title={props.title}
 		    titleTypographyProps={{ align: "center", variant: "subtitle1"}}
		    subheader={format(",")(props.target)}
		    subheaderTypographyProps={{ align: "center", }}
		    action={props.icon} />
        <CardContent>
            <Gauge value={props.value}
	           target={props.target}
	           min={props.min}
		   max={props.max}
		   units={props.units} />
	</CardContent>
	<CardActions disableSpacing>
	    <Tooltip title="Historical Trend" placement="bottom">
	      <IconButton size="small"
	                  color={props.displayTrend === true ? "secondary": "inherit"}
		  	  onClick={props.toggleTrend} >
                <TimelineIcon fontSize="small" />
	      </IconButton>
            </Tooltip>
	    <Tooltip title={props.appTooltip} placement="bottom">
	      <IconButton size="small"
	                  color={props.displayAppTrend === true ? "secondary": "inherit"}
		  	  onClick={props.toggleAppTrend} >
                <BarChartIcon fontSize="small" />
	      </IconButton>
            </Tooltip>
	</CardActions>
    </Card>
  );
}
