import { connect } from 'react-redux';
import DrillStateView from './drillStateView';
import { newRoptimizerSteadyState,
	 newDrillState,
	 setDisplayTrends } from '../../redux/actions/data';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  const property = ownProps.type + 'SteadyState';
  const params = {
      title: ownProps.type,
      steadyState: state.roptimizerSteadyState[property],
      drilling: state.rigObjective.objective === 4,
      socket: state.connection.socket,
      loggedIn: state.auth.loggedIn,
      drillState: state.drillState,
      limits: state.limits,
      displayRopTrend: state.displayTrends["rop"].display,
      displayWobTrend: state.displayTrends["wob"].display,
      displayDiffpTrend: state.displayTrends["diffp"].display,
      displayTorqueTrend: state.displayTrends["difft"].display,
  };
  return params;
};

const mapDispatchToProps = function (dispatch) {
    return {
      watchDrillState(socket) {
	socket.on('drillState', (drillState) => {
        dispatch(newDrillState(drillState));
        });
      },
      watchRoptimizerSteadyState(socket) {
        socket.on('roptimizerSteadyState', (roptimizerData) => {
        dispatch(newRoptimizerSteadyState(roptimizerData));
        });
      },
      toggleTrend(trend, display) {
          dispatch(setDisplayTrends(trend, display));
      },
    };
};

const DrillStateViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DrillStateView);

export default DrillStateViewContainer;
