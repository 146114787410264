import { connect } from 'react-redux';
import RequestsModal from './RequestsModal';
import { sendRoptimizerConfigurationRequest } from '../../redux/actions/data';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    roptimizerConfiguration: state.roptimizerConfiguration,
    socket: state.connection.socket,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    submitRoptimizerConfigurationRequest(socket, data) {
      socket.emit('RoptimizerConfigurationRequest', data);
      dispatch(sendRoptimizerConfigurationRequest(data));
    },
  };
};

const RequestsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(RequestsModal);

export default RequestsModalContainer;
