import './layout.css';
import AppBarAndDrawerContainer from '../../components/AppBarAndDrawer/appBarAndDrawerContainer';
import Auth0Lock from 'auth0-lock';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import React from 'react';
import ResourcesSnackbarManagerContainer from '../ResourcesSnackbarManager/resourcesSnackbarManagerContainer';
import Routes from '../../routes';
import { Route, Switch } from 'react-router-dom';

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lock: null,
    };

    this.showLock = this.showLock.bind(this);
    this.stopDrilling = this.stopDrilling.bind(this);
    this.startDrilling = this.startDrilling.bind(this);
  }

  showLock() {
    this.state.lock.show();
  }

  componentDidMount() {
    if (process.env.REACT_APP_USE_AUTH === 'true') {
      const that = this;

      // Listen for the authenticated event and get profile
      const lock = new Auth0Lock(clientId, domain, {
        theme: {
          primaryColor: '#000000',
          logo: 'https://untitledfolder-ltdwtzlzlp.now.sh/',
        },
        languageDictionary: {
          title: 'Ensign Edge',
        },
        autoclose: true,
      });

      lock.on('authenticated', function (authResult) {
        lock.getUserInfo(authResult.accessToken, function (error, profile) {
          if (error) {
            return error;
          }

          localStorage.setItem(
            'accessToken',
            JSON.stringify(authResult.accessToken),
          );
          localStorage.setItem('profile', JSON.stringify(profile));
          localStorage.setItem('user', profile.name);
          localStorage.setItem('picture', profile.picture);
          localStorage.setItem('email', profile.email);

          that.props.login(profile);
	  console.log(profile);

	  const forceUom = JSON.parse(localStorage.getItem('force'));
	  if (!forceUom) {
	      localStorage.setItem('force', 'klb');
	  }

	  const torqueUom = JSON.parse(localStorage.getItem('torque'));
	  if (!torqueUom) {
	      localStorage.setItem('torque', 'Nm');
	  }

	  const lengthUom = JSON.parse(localStorage.getItem('length'));
	  if (!lengthUom) {
	      localStorage.setItem('length', 'ft');
	  }

	  const velocityUom = JSON.parse(localStorage.getItem('velocity'));
	  if (!velocityUom) {
	      localStorage.setItem('velocity', 'ft/h');
	  }

	  const angularVelocityUom = JSON.parse(localStorage.getItem('angularVelocity'));
	  if (!angularVelocityUom) {
	      localStorage.setItem('angularVelocity', 'rpm');
	  }

	  const pressureUom = JSON.parse(localStorage.getItem('pressure'));
	  if (!pressureUom) {
	      localStorage.setItem('pressure', 'psi');
	  }

	  const volumeFlowRateUom = JSON.parse(localStorage.getItem('volumeFlowRate'));
	  if (!volumeFlowRateUom) {
	      localStorage.setItem('volumeFlowRate', 'gpm');
	  }
	  });
      });

      const locallyStoredUser = JSON.parse(localStorage.getItem('accessToken'));
      if (locallyStoredUser) {
        that.props.login(JSON.parse(localStorage.getItem('profile')));
	
      }

      this.setState({ lock });
    }
  }

  startDrilling() {
    if (this.props.socket) {
      this.props.socket.emit('enableDrilling');
    } else {
      let that = this;
      console.log('start Drilling');
      console.log('Socket not connected');
      setTimeout(function () {
        that.startDrilling();
      }, 100);
    }
  }

  stopDrilling() {
    if (this.props.socket) {
      this.props.socket.emit('disableDrilling');
    } else {
      let that = this;
      console.log('stop drilling');
      console.log('Socket not connected');
      setTimeout(function () {
        that.stopDrilling();
      }, 100);
    }
  }

  render() {
    if (process.env.REACT_APP_USE_AUTH === 'true' && !this.props.loggedIn) {
      return (
        <div className="signin-btn">
          <img
            src="https://www.ensignenergy.com/img/logo-dark.png"
            alt="ensign-logo"
          />
          <button className="btn" onClick={this.showLock}>
            Sign In
          </button>
        </div>
      );
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBarAndDrawerContainer
	  location={this.props.location}
          login={this.props.login}
          logout={this.props.logout}
          drilling={this.props.drilling}
          lock={this.state.lock}
          onChangeTheme={this.props.onChangeTheme}
          stopDrilling={this.stopDrilling}
          startDrilling={this.startDrilling}
        />
        <Switch>
          {Routes.map((route) => {
            if (route.exact) {
              return (
                <Route
                  exact
                  path={route.path}
                  component={route.component}
                  key={route.path}
                />
              );
            } else {
              return (
                <Route
                  path={route.path}
                  component={route.component}
                  key={route.path}
                />
              );
            }
          })}
        </Switch>
        <ResourcesSnackbarManagerContainer />
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  login: PropTypes.func,
  logout: PropTypes.func,
  onChangeTheme: PropTypes.func,
  objective: PropTypes.string,
};

export default Layout;
