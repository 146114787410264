import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_WELLBORE_STATE,
} from '../actions/data';

export default function wellboreState(state, action) {
  if (typeof state === 'undefined') {
      return {
	  validData: false,
          millisecondsEpochUTC: null,
	  holeDepth: 0,
	  measuredDepth: 0,
	  trueVerticalDepth: 0,
	  bitDepth: 0,
      };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
  case SOCKET_ON_WELLBORE_STATE:
      newState.validData = true;
      newState.millisecondsEpochUTC = action.wellboreState.millisecondsEpochUTC;
      newState.holeDepth = action.wellboreState.holeDepth;
      newState.measuredDepth = action.wellboreState.measuredDepth;
      newState.trueVerticalDepth = action.wellboreState.trueVerticalDepth;
      newState.bitDepth = action.wellboreState.bitDepth;
      return newState;
    default:
      return state;
  }
}
