import DrillLimitsContainer from '../DrillLimitsConfiguration/drillLimitsContainer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import RealTimeDataCard from '../Common/realTimeDataCard';
import SettingsIcon from '@material-ui/icons/Settings';
import SteadyStateIndicatorSelectorContainer from '../RoptimizerApplication/steadyStateIndicatorSelectorContainer';
import Tooltip from '@material-ui/core/Tooltip';
import { Redirect } from 'react-router-dom';
import { velocity } from "units-converter";
import { force } from "units-converter";
import { torque } from "units-converter";
import { pressure } from "units-converter";

import './drillStateView.css';

export default class DrillStateView extends React.Component {
    constructor(props) {
        super(props);

    this.openConfigurationModal = this.openConfigurationModal.bind(this);
    this.toggleRopTrend = this.toggleRopTrend.bind(this);
    this.toggleWobTrend = this.toggleWobTrend.bind(this);
    this.toggleDiffpTrend = this.toggleDiffpTrend.bind(this);
    this.toggleTorqueTrend = this.toggleTorqueTrend.bind(this); 
    this.drillParamConfigRef = React.createRef();
  }

  componentDidMount() {
	if (this.props.socket) {
	    this.props.watchRoptimizerSteadyState(this.props.socket);
	    this.props.socket.emit('getDrillStateHistory');
	}
  };
    
  componentWillUnmount() {
	if (this.props.socket) {
	    this.props.socket.off("roptimizerSteadyState");
	}
  }

    toggleRopTrend(name) {
        this.props.toggleTrend("rop", !this.props.displayRopTrend);
    }

    toggleWobTrend(name) {
        this.props.toggleTrend("wob", !this.props.displayWobTrend);
    }

    toggleDiffpTrend(name) {
        this.props.toggleTrend("diffp", !this.props.displayDiffpTrend);
    }

    toggleTorqueTrend(name) {
        this.props.toggleTrend("difft", !this.props.displayTorqueTrend);
    }

    openConfigurationModal() {
      this.drillParamConfigRef.current.openModal();
    }

    render() {
      if (process.env.REACT_APP_USE_AUTH === 'true' && !this.props.loggedIn) {
        return <Redirect to={'/'} push />;
      }

      const limits = this.props.limits;
      const drillState = this.props.drillState;

      if (drillState.valid === false) {
          return (
              <React.Fragment/>
          )}
      
      const velocityUom = localStorage.getItem("velocity");
      var ropActual = velocity(drillState.rop.actual).from('m/s').to(velocityUom);
      var ropTarget = velocity(drillState.rop.target).from('m/s').to(velocityUom);
      var ropMin = velocity(limits.ropMin).from('m/s').to(velocityUom);
      var ropMax = velocity(limits.ropMax).from('m/s').to(velocityUom);

      const forceUom = localStorage.getItem("force");
      var wobActual = force(drillState.wob.actual).from('N').to(forceUom);
      var wobTarget = force(drillState.wob.target).from('N').to(forceUom);
      var wobMin = force(limits.wobMin).from('N').to(forceUom);
      var wobMax = force(limits.wobMax).from('N').to(forceUom);

      const pressureUom = localStorage.getItem("pressure");
      var diffpActual = pressure(drillState.diffp.actual).from('Pa').to(pressureUom);
      var diffpTarget = pressure(drillState.diffp.target).from('Pa').to(pressureUom);
      var diffpMin = pressure(limits.diffPMin).from('Pa').to(pressureUom);
      var diffpMax = pressure(limits.diffPMax).from('Pa').to(pressureUom);

      const torqueUom = localStorage.getItem("torque");
      var torqueActual = torque(drillState.torque.actual).from('Nm').to(torqueUom);
      var torqueTarget = torque(drillState.torque.target).from('Nm').to(torqueUom);
      var torqueMin = torque(limits.torqueMin).from('Nm').to(torqueUom);
      var torqueMax = torque(limits.torqueMax).from('Nm').to(torqueUom);

      return (
         <React.Fragment>
	        <Grid item xs={12} container direction="row" spacing={2} alignItems="center" justify="space-evenly">
                  <RealTimeDataCard title="ROP"
				    icon={<SteadyStateIndicatorSelectorContainer type="rpm" drilling={this.props.drilling} />} 
		                    target={Number.parseFloat(ropTarget.value).toFixed()}
				    value={Number.parseFloat(ropActual.value).toFixed()}
				    min={Number.parseFloat(ropMin.value).toFixed()}
			            max={Number.parseFloat(ropMax.value).toFixed()}
				    units={velocityUom}
			    	    displayTrend={this.props.displayRopTrend}
                            	    toggleTrend={this.toggleRopTrend} />
                  <RealTimeDataCard title="WOB"
		                    target={Number.parseFloat(wobTarget.value).toFixed()}
				    value={Number.parseFloat(wobActual.value).toFixed()}
				    min={Number.parseFloat(wobMin.value).toFixed()}
			            max={Number.parseFloat(wobMax.value).toFixed()}
				    units={forceUom}
			    	    displayTrend={this.props.displayWobTrend}
                            	    toggleTrend={this.toggleWobTrend}
				    icon={<SteadyStateIndicatorSelectorContainer type="wob" drilling={this.props.drilling} /> } />
          	  <RealTimeDataCard title="DiffP"
		                    target={Number.parseFloat(diffpTarget.value).toFixed()}
				    value={Number.parseFloat(diffpActual.value).toFixed()}
				    min={Number.parseFloat(diffpMin.value).toFixed()}
			            max={Number.parseFloat(diffpMax.value).toFixed()}
			            units={pressureUom}
			            displayTrend={this.props.displayDiffpTrend}
                                    toggleTrend={this.toggleDiffpTrend} />
                  <RealTimeDataCard title="Torque"
		  	            icon={<SteadyStateIndicatorSelectorContainer type="torque" drilling={this.props.drilling} /> }
		                    target={Number.parseFloat(torqueTarget.value).toFixed()}
				    value={Number.parseFloat(torqueActual.value).toFixed()}
				    min={Number.parseFloat(torqueMin.value).toFixed()}
			            max={Number.parseFloat(torqueMax.value).toFixed()}
			            units={torqueUom}
			            displayTrend={this.props.displayTorqueTrend}
                                    toggleTrend={this.toggleTorqueTrend} />
		</Grid>
	    <DrillLimitsContainer ref={this.drillParamConfigRef} />
           </React.Fragment>
    );
  }
}
