import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { format } from "d3-format"

export default class DrillingMetricsView extends React.Component {
  componentDidMount() {
	if (this.props.socket) {
	    this.props.watchDrillingMetrics(this.props.socket);
	}
  };
    
  componentWillUnmount() {
	if (this.props.socket) {
	    this.props.socket.off("drillingMetrics");
	}
  }

    render() {
        const drillingMetrics = this.props.drillingMetrics;

        if (drillingMetrics.valid === false) {
            return (
                <React.Fragment/>
            )}

	return (
	   <React.Fragment>
            <Card >
                <CardHeader title="Drilling Metrics"
                            titleTypographyProps={{ align: "center", variant: "h6"}}  />
                <CardContent>
		<Grid item container xs={12}>
		  <Grid item xs={6}>
   	            <Typography align="right">Bit MSE:</Typography>
		  </Grid>
		  <Grid item xs={6}>
   	            <Typography align="right">{ format(" >12,.0f")(((drillingMetrics.bitMse / 1000) /1000))} Ksi</Typography>
		  </Grid>
		  <Grid item xs={6}>
   	            <Typography align="right">Surface MSE:</Typography>
		  </Grid>
		  <Grid item xs={6}>
   	            <Typography align="right">{ format("8,.0f")(((drillingMetrics.surfaceMse / 1000) / 1000))} Ksi</Typography>
		  </Grid>
		  <Grid item xs={6}>
   	            <Typography align="right">Slip Stick Severity:</Typography>
		  </Grid>
		  <Grid item xs={6}>
   	            <Typography align="right">{ format("8,.0%")(drillingMetrics.stickSlipSeverity)}</Typography>
		  </Grid>
		  <Grid item xs={6}>
   	            <Typography align="right">Depth of Cut:</Typography>
		  </Grid>
		  <Grid item xs={6}>
   	            <Typography align="right">{ format("8,.1f")(drillingMetrics.depthOfCut)}</Typography>
		  </Grid>
	        </Grid>
                </CardContent>
            </Card>
       </React.Fragment>
    );
  }
}
