import deepmerge from '../../helpers/deepmerge';
import { SOCKET_ON_DRILLING_CALIBRATION } from '../actions/autotunerData';

export default function drillingCalibration(state, action) {
  if (typeof state === 'undefined') {
    return {
      timestamp: { sec: 0, nanosec: 0 },
      wobProportional: 0.0,
      wobIntegral: 0.0,
      differentialPressureProportional: 0.0,
      differentialPressureIntegral: 0.0,
      torqueProportional: 0.0,
      torqueIntegral: 0.0,
      minWobProportional: 0.0,
      maxWobProportional: 0.0,
      minWobIntegral: 0.0,
      maxWobIntegral: 0.0,
      minDifferentialPressureProportional: 0.0,
      maxDifferentialPressureProportional: 0.0,
      minDifferentialPressureIntegral: 0.0,
      maxDifferentialPressureIntegral: 0.0,
      minTorqueProportional: 0.0,
      maxTorqueProportional: 0.0,
      minTorqueIntegral: 0.0,
      maxTorqueIntegral: 0.0,
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
    case SOCKET_ON_DRILLING_CALIBRATION:
      newState.timestamp = action.drillingCalibrationData.timestamp;
      newState.wobProportional = action.drillingCalibrationData.wobProportional;
      newState.wobIntegral = action.drillingCalibrationData.wobIntegral;
      newState.differentialPressureProportional = action.drillingCalibrationData.differentialPressureProportional;
      newState.differentialPressureIntegral = action.drillingCalibrationData.differentialPressureIntegral;
      newState.torqueProportional = action.drillingCalibrationData.torqueProportional;
      newState.torqueIntegral = action.drillingCalibrationData.torqueIntegral;
      newState.minWobProportional = action.drillingCalibrationData.minWobProportional;
      newState.maxWobProportional = action.drillingCalibrationData.maxWobProportional;
      newState.minWobIntegral = action.drillingCalibrationData.minWobIntegral;
      newState.maxWobIntegral = action.drillingCalibrationData.maxWobIntegral;
      newState.minDifferentialPressureProportional = action.drillingCalibrationData.minDifferentialPressureProportional;
      newState.maxDifferentialPressureProportional = action.drillingCalibrationData.maxDifferentialPressureProportional;
      newState.minDifferentialPressureIntegral = action.drillingCalibrationData.minDifferentialPressureIntegral;
      newState.maxDifferentialPressureIntegral = action.drillingCalibrationData.maxDifferentialPressureIntegral;
      newState.minTorqueProportional = action.drillingCalibrationData.minTorqueProportional;
      newState.maxTorqueProportional = action.drillingCalibrationData.maxTorqueProportional;
      newState.minTorqueIntegral = action.drillingCalibrationData.minTorqueIntegral;
      newState.maxTorqueIntegral = action.drillingCalibrationData.maxTorqueIntegral;
      return newState;
    default:
      return state;
  }
}
