import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { createInputWithTooltip,
         createInputWithTooltipUnit,
	 createSubmitButton  } from '../Common/CreateInputsHelperMethods';

class AutoTunerTuning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.autoTunerConfiguration.id,
      filter: props.autoTunerConfiguration.filter,
      d: props.autoTunerConfiguration.d,
      f: props.autoTunerConfiguration.f,
      epsilonManual: props.autoTunerConfiguration.epsilonManual,
      epsilon: props.autoTunerConfiguration.epsilon,
      kcMax: props.autoTunerConfiguration.kcMax,
      kcMin: props.autoTunerConfiguration.kcMin,
      tiMax: props.autoTunerConfiguration.tiMax,
      tiMin: props.autoTunerConfiguration.tiMin,

      initK: props.autoTunerConfiguration.initK,
      initTau: props.autoTunerConfiguration.initTau,
      initPreFilter: props.autoTunerConfiguration.initPreFilter,
      r1: props.autoTunerConfiguration.r1,
      r2: props.autoTunerConfiguration.r2,
    };

    this.updateInput = this.updateInput.bind(this);
    this.configurationRequest = this.configurationRequest.bind(this);
  }

  configurationRequest() {
    const {
      id, 
      filter,
      d,
      f,
      epsilonManual,
      epsilon,
      kcMax,
      kcMin,
      tiMax,
      tiMin,
      initK,
      initTau,
      initPreFilter,
      r1,
      r2,
    } = this.state;

    const requestData = {
      id, 
      filter,
      d,
      f,
      epsilonManual,
      epsilon,
      kcMax,
      kcMin,
      tiMax,
      tiMin,
      initK,
      initTau,
      initPreFilter,
      r1,
      r2,
    };

    this.props.configurationRequest(requestData.id, requestData);
  }

  updateInput(name) {
    let id = name.replace(this.props.autoTunerConfiguration.id,"");
    let that = this;
    return function (event) {
      that.setState({ [id]: event.target.value });
    };
  }

  render() {
    const config = this.props.autoTunerConfiguration;
    return (
      <Grid container xs={4}
        id="modal-content-tab-content-at-model-state"
        justify="center"
        spacing={2}>
      <Grid item>
        <Card className="configuration-request-card" variant="outlined">
          <CardHeader title={config.id} titleTypographyProps={{ align: "center", variant: "subtitle1"}} />
          <CardContent>
            <Grid container xs={4} spacing={2} justify="center">
 	      <Grid item xs={12}>
              {createInputWithTooltip(
                config.id + 'filter',
                'Filter',
                config.filter,
                this.state.filter,
                this.updateInput,
              )}
	      </Grid>
 	      <Grid item xs={12}>
              {createInputWithTooltip(
                config.id + 'd',
                'D',
                config.d,
                this.state.d,
                this.updateInput,
              )}
	      </Grid>
 	      <Grid item xs={12}>
              {createInputWithTooltip(
                config.id + 'f',
                'F',
                config.f,
                this.state.f,
                this.updateInput,
              )}
	      </Grid>
 	      <Grid item xs={12}>
              {createInputWithTooltip(
                config.id + 'kcMin',
                'Kc Min',
                config.kcMin,
                this.state.kcMin,
                this.updateInput,
              )}
	      </Grid>
 	      <Grid item xs={12}>
              {createInputWithTooltip(
                config.id + 'kcMax',
                'Kc Max',
                config.kcMax,
                this.state.kcMax,
                this.updateInput,
              )}
	      </Grid>
 	      <Grid item xs={12}>
              {createInputWithTooltipUnit(
                config.id + 'tiMin',
                'Ti Min',
   	        'sec',
                config.tiMin,
                this.state.tiMin,
                this.updateInput,
              )}
	      </Grid>
 	      <Grid item xs={12}>
              {createInputWithTooltipUnit(
                config.id + 'tiMax',
                'Ti Max',
   	        'sec',
                config.tiMax,
                this.state.tiMax,
                this.updateInput,
              )}
	      </Grid>
            </Grid>
            <Grid item xs={12} align="center">
	      {createSubmitButton(
		config.id + 'Update',
		this.configurationRequest)}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      </Grid>
    );
  }
}

export default AutoTunerTuning;
