import React from 'react';
import PropTypes from 'prop-types';
import LensIcon from '@material-ui/icons/Lens';

class SteadyStateIndicator extends React.Component {
  render() {
    if (this.props.drilling === false) {
       return (
         <React.Fragment/>
     )}

    const receivedSteadyState = this.props.steadyState !== null;
    const steadyState = this.props.steadyState;

    const green = '#0f9803';
    const red = '#900606';

    let ledColor;
    if (receivedSteadyState && this.props.drilling) {
      if (steadyState) {
        ledColor = green;
      } else {
        ledColor = red;
      }
    }

    return (
      <LensIcon className="lens-led" style={{ color: ledColor }} />
    );
  }
}

SteadyStateIndicator.propTypes = {
  steadyState: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default SteadyStateIndicator;
