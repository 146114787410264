import io from "socket.io-client";

export const LOGIN_PROCESSING = 'LOGIN_PROCESSING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const ACTIVE_RIGS = 'ACTIVE_RIGS';

function loginProcessing(processing) {
  return {
    type: LOGIN_PROCESSING,
    processing,
  };
}

function loginSuccess(user) {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

function activeRigs(rigs) {
  return {
      type: ACTIVE_RIGS,
      rigs,
  };
}

// Logs the user in
export function requestLogin(profile) {
    return (dispatch) => {
        let authUrl = process.env.REACT_APP_RBAC_AUTH + ':25000';
        let authServer = io(authUrl, {upgrade: false, transports: ['websocket'], });

        authServer.on('connect', () => {
            authServer.emit('authorizeUser', profile);
            authServer.emit('selectAllRigs');
        });
        authServer.on('userAuthorized', (user) => {
            authServer.emit('startSession', user[0]);
            dispatch(loginSuccess(user));
            dispatch(loginProcessing(false));
        });
        authServer.on('allRigs', (rigs) => {
            dispatch(activeRigs(rigs));
        });
        dispatch(loginProcessing(true));
    };
}

// Logs the user out
export function logoutUser() {
  return dispatch => {
    dispatch(requestLogout());
    localStorage.removeItem('id_token');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('profile');
    dispatch(receiveLogout());
  };
}
