import { connect } from 'react-redux';
import PressureView from './pressureView';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    socket: state.connection.socket,
    standpipePressure: state.circulateState.standpipePressure,
    hookload: state.hoistState.hookload,
  };
};

const PressureViewContainer = connect(
  mapStateToProps,
)(PressureView);

export default PressureViewContainer;
