import { connect } from 'react-redux';
import WellPlanView from './wellPlanView';

const mapStateToProps = function mapStateToProps(state, ownProps) {
    return {
        loggedIn: state.auth.loggedIn,
        limits: state.limits,
        connected: state.connection.connected,
        socket: state.connection.socket,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {};
};

const WellPlanViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WellPlanView);

export default WellPlanViewContainer;
