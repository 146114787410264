import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PauseIcon from '@material-ui/icons/PauseCircleOutline';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import StopIcon from '@material-ui/icons/Stop';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';


const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function handleClick(e, operation, socket) {
    e.preventDefault();
    if (operation === "pauseData") {
    } else if (operation === "uploadSimlatorData") {
    } else if (operation === "startDrilling") {
	console.log("Calling Start Drilling")
	if (socket.connected) {
	    socket.emit('enableDrilling');
	}
    } else if (operation === "stopDrilling") {
	console.log("Calling Stop Drilling")
	if (socket.connected) {
            socket.emit('disableDrilling');
	}
    }
}

export default function SimulatorRemote(props) {
    const display = props.display;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const actions = [
	{ icon: <PlayIcon />, name: 'Start Simulator', operation: 'startDrilling' },
	{ icon: <StopIcon />, name: 'Stop Simulator', operation: 'stopDrilling'  },
	{ icon: <PauseIcon />, name: 'Pause Data', operation: 'pauseData' },
	{ icon: <CloudUploadIcon />, name: 'Upload Simulator File', operation: 'uploadSimulatorData' },
    ];
    
    const handleOpen = () => {
	setOpen(true);
    };

    const handleClose = () => {
	setOpen(false);
    };

    return (
     <React.Fragment>
         <SpeedDial ariaLabel="SpeedDial openIcon example"
                    className={classes.speedDial}
                    hidden={!display}
	            direction='left'
                    icon={<SpeedDialIcon />}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
         >
              {actions.map((action) => (
	         <SpeedDialAction key={action.name}
		                  icon={action.icon}
		                  tooltipTitle={action.name}
                  onClick={(e) => {
		      handleClick(e, action.operation, props.socket)
		  }}
                  />
	      ))}
           </SpeedDial>
     </React.Fragment>
    )
}
