import { connect } from 'react-redux';
import AppBarAndDrawer from './AppBarAndDrawer';
import {
    initializeStore,
    closeConnection,
    openConnection,
} from '../../redux/actions/data';

const mapStateToProps = function mapStateToProps(state) {
  return {
      loggedIn: state.auth.loggedIn,
      rigs: state.activeRigs,
      connected: state.connection.connected,
      socket: state.connection.socket,
  };
};

const mapDispatchToProps = function (dispatch) {
    return {
	resetStore() {
	    dispatch(initializeStore());
	},
	connectToServer(selection) {
	    dispatch(openConnection(selection));
	},
	disconnectFromServer(socket) {
            dispatch(closeConnection(socket));
	},
    };
};

const AppBarAndDrawerContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppBarAndDrawer);

export default AppBarAndDrawerContainer;
