import { connect } from 'react-redux';
import FileUploadModal from './FileUploadModal';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  return {};
};

const mapDispatchToProps = function (dispatch) {
  return {};
};

const FileUploadModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(FileUploadModal);

export default FileUploadModalContainer;
