import { connect } from 'react-redux';
import ObjectiveView from './objectiveView';

const mapStateToProps = function mapStateToProps(state, ownProps) {
    return {
	connection: state.connection,
	connected: state.connection.connected,
	actualPosition: state.hoistState.actualPosition,
	rigObjective: state.rigObjective,
	drilling: state.rigObjective.objective === 4,
	sliding: state.rigObjective.objective === 5,
  };
};

const ObjectiveViewContainer = connect(
  mapStateToProps,
)(ObjectiveView);

export default ObjectiveViewContainer;
