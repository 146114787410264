export const SOCKET_EMIT_CONFIGURATION_REQUEST = 'SOCKET_EMIT_CONFIGURATION_REQUEST';
export const SOCKET_ON_DRILLING_CALIBRATION = 'SOCKET_ON_DRILLING_CALIBRATION';
export const SOCKET_ON_DRILLING_CALIBRATION_REQUEST = 'SOCKET_ON_DRILLING_CALIBRATION_REQUEST';
export const STOP_ALL_SOCKET_LISTENERS = 'STOP_ALL_SOCKET_LISTENERS';

export function newDrillingCalibrationData(drillingCalibrationData) {
  return {
    type: SOCKET_ON_DRILLING_CALIBRATION,
    drillingCalibrationData,
  }
};

export function newDrillingCalibrationRequest(drillingCalibrationRequest) {
  return {
    type: SOCKET_ON_DRILLING_CALIBRATION_REQUEST,
    drillingCalibrationRequest,
  }
};

export function sendConfigurationRequest(data) {
  return {
    type: SOCKET_EMIT_CONFIGURATION_REQUEST,
    data,
  };
};

export function stopAllSocketListeners(socket) {
  return {
    type: STOP_ALL_SOCKET_LISTENERS,
  };
};
