import deepmerge from '../../helpers/deepmerge';
import {
    SOCKET_ON_ROTATE_STATE,
} from '../actions/data';

export default function rotateState(state, action) {
  if (typeof state === 'undefined') {
      return {
          valid: false,
          target: 0,
          actual: 0,
	  min: 0,
	  max: 0,
          data: [],
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
      case SOCKET_ON_ROTATE_STATE:
          newState.valid = true;
          newState.actual = action.rotateState.actualRate;
          newState.target = action.rotateState.targetRate;
          newState.min = action.rotateState.minRate;
          newState.max = action.rotateState.maxRate;

          newState.data = newState.data.concat(action.rotateState);
          if (newState.data.length > process.env.REACT_APP_DATA_POINT_LIMIT) {
              newState.data = newState.data.slice(newState.data.length - process.env.REACT_APP_DATA_POINT_LIMIT);
	  }
      return newState;
    default:
      return state;
  }
}
