import Backdrop from '@material-ui/core/Backdrop';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress  from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { angularVelocity } from "units-converter";
import { createInputWithTooltipUnit, } from '../Common/CreateInputsHelperMethods';
import { force } from "units-converter";
import { length } from "units-converter";
import { pressure } from "units-converter";
import { torque } from "units-converter";
import { velocity } from "units-converter";

import './wellPlanView.css';


export default class WellPlanView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            startDepth: this.props.limits.startDepth,
            endDepth: this.props.limits.endDepth,
            ropMin: this.props.limits.ropMin,
            ropMax: this.props.limits.ropMax,
            wobMin: this.props.limits.wobMin,
            wobMax: this.props.limits.wobMax,
            diffPMin: this.props.limits.diffPMin,
            diffPMax: this.props.limits.diffPMax,
            torqueMin: this.props.limits.torqueMin,
            torqueMax: this.props.limits.torqueMax,
            rotateMin: this.props.limits.rotateMin,
            rotateMax: this.props.limits.rotateMax,
        };

        this.submitDrillingLimitsConfigurationRequest = this.submitDrillingLimitsConfigurationRequest.bind(this);
        this.updateInput = this.updateInput.bind(this);
    }

    updateInput(event) {
    let that=this;
    return function (event) {
            that.setState({ [event.target.id]: parseInt(event.target.value) });
        };
    }

    componentDidUpdate(prevProps, prevState) {
      console.log('wellPlanView::componentDidUpdate()');
  }

    componentDidMount() {
        console.log('wellPlanView::componentDidMount()');
    }

    submitDrillingLimitsConfigurationRequest() {
        const angularVelocityUom = localStorage.getItem("angularVelocity");
        const forceUom = localStorage.getItem("force");
        const lengthUom = localStorage.getItem("length");
        const pressureUom = localStorage.getItem("pressure");
        const torqueUom = localStorage.getItem("torque");
        const velocityUom = localStorage.getItem("velocity");

        var startDepth = length(this.state.startDepth).from(lengthUom).to('m');
        var endDepth = length(this.state.endDepth).from(lengthUom).to('m');
        var ropMin = velocity(this.state.ropMin).from(velocityUom).to('m/s');
        var ropMax = velocity(this.state.ropMax).from(velocityUom).to('m/s');
        var wobMin = force(this.state.wobMin).from(forceUom).to('N');
        var wobMax = force(this.state.wobMax).from(forceUom).to('N');
        var diffPMin = pressure(this.state.diffPMin).from(pressureUom).to('Pa');
        var diffPMax = pressure(this.state.diffPMax).from(pressureUom).to('Pa');
        var torqueMin = torque(this.state.torqueMin).from(torqueUom).to('Nm');
        var torqueMax = torque(this.state.torqueMax).from(torqueUom).to('Nm');
        var rotateMin = angularVelocity(this.state.rotateMin).from(angularVelocityUom).to('rad/s');
        var rotateMax = angularVelocity(this.state.rotateMax).from(angularVelocityUom).to('rad/s');

	let configurationData = {
            startDepth: startDepth.value,
            endDepth: endDepth.value,
            ropMin: ropMin.value,
            ropMax: ropMax.value,
            wobMin: wobMin.value,
            wobMax: wobMax.value,
            diffPMin: diffPMin.value,
            diffPMax: diffPMax.value,
            torqueMin: torqueMin.value,
            torqueMax: torqueMax.value,
            rotateMin: rotateMin.value,
            rotateMax: rotateMax.value,
        };
        this.props.socket.emit('DrillingLimitsConfigurationRequest', configurationData);
    }

    render() {
       if (this.props.connected === false ) {
           return (
              <React.Fragment>
                <Backdrop open={!this.props.connected}>
                  <Typography>Establishing Remote Connection...</Typography>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </React.Fragment>
           )
        }
        if (process.env.REACT_APP_USE_AUTH === 'true' && !this.props.loggedIn) {
            return <Redirect to={'/'} push />;
        }
        const config = this.props.limits;

        const angularVelocityUom = localStorage.getItem("angularVelocity");
        const forceUom = localStorage.getItem("force");
        const lengthUom = localStorage.getItem("length");
        const pressureUom = localStorage.getItem("pressure");
        const torqueUom = localStorage.getItem("torque");
        const velocityUom = localStorage.getItem("velocity");

        var startDepth = length(config.startDepth).from('m').to(lengthUom);
        var endDepth = length(config.endDepth).from('m').to(lengthUom);
        var ropMin = velocity(config.ropMin).from('m/s').to(velocityUom);
        var ropMax = velocity(config.ropMax).from('m/s').to(velocityUom);
        var wobMin = force(config.wobMin).from('N').to(forceUom);
        var wobMax = force(config.wobMax).from('N').to(forceUom);
        var diffpMin = pressure(config.diffPMin).from('Pa').to(pressureUom);
        var diffpMax = pressure(config.diffPMax).from('Pa').to(pressureUom);
        var torqueMin = torque(config.torqueMin).from('Nm').to(torqueUom);
        var torqueMax = torque(config.torqueMax).from('Nm').to(torqueUom);
        var rotateMin = angularVelocity(config.rotateMin).from('rad/s').to(angularVelocityUom);
        var rotateMax = angularVelocity(config.rotateMax).from('rad/s').to(angularVelocityUom);
        
        return (
            <div id="well-plan-content">
              <Card raised className="well-plan-card">
                <CardHeader title="Drilling Limits" />
                <Grid container
                      justify="center"
                      display="row"
                      alignItems="center"
                      spacing={4} >
                  {createInputWithTooltipUnit(
                      'startDepth',
                      'Start Depth',
                      lengthUom,
                      startDepth.value,
                      startDepth.value,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'endDepth',
                      'End Depth',
                      lengthUom,
                      Math.round(endDepth.value),
                      Math.round(endDepth.value),
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'ropMin',
                      'ROP Min',
                      velocityUom,
                      Math.round(Number(ropMin.value)),
                      Math.round(Number(ropMin.value)),
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'ropMax',
                      'ROP Max',
                      velocityUom,
                      Math.round(Number(ropMax.value)),
                      Math.round(Number(ropMax.value)),
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'wobMin',
                      'WOB Min',
                      forceUom,
                      Math.round(Number(wobMin.value)),
                      Math.round(Number(wobMin.value)),
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'wobMax',
                      'WOB Max',
                      forceUom,
                      Math.round(Number(wobMax.value)),
                      Math.round(Number(wobMax.value)),
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'diffPMin',
                      'Diff PMin',
                      pressureUom,
                      diffpMin.value,
                      diffpMin.value,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'diffPMax',
                      'Diff PMax',
                      pressureUom,
                      diffpMax.value,
                      diffpMax.value,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'torqueMin',
                      'Torque Min',
                      torqueUom,
                      torqueMin.value,
                      torqueMin.value,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'torqueMax',
                      'Torque Max',
                      torqueUom,
                      torqueMax.value,
                      torqueMax.value,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'rotateMin',
                      'Rotate Min',
                      angularVelocityUom,
                      rotateMin.value,
                      rotateMin.value,
                      this.updateInput,
                  )}
                  {createInputWithTooltipUnit(
                      'rotateMax',
                      'Rotate Max',
                      angularVelocityUom,
                      rotateMax.value,
                      rotateMax.value,
                      this.updateInput,
                  )}
               </Grid>
               <Grid conatiner
                  justify="center"
                  display="column"
                  alignItems="center"
                  spacing={8}
                  >
                  <Grid item
                  justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={this.submitDrillingLimitsConfigurationRequest}
                    >
                    Submit
                  </Button>
                </Grid>
               </Grid>
              </Card>
            </div>
        );
    }
}
