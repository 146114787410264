import { connect } from 'react-redux';
import DygraphLineGraph from '../LineGraph/dygraphLineGraph';
import { volumeFlowRate } from "units-converter";

const mapStateToProps = function mapStateToProps(state, ownProps) {
    let params = {};
    if (ownProps.type === 'Flow') {
	const volumeFlowRateUom = localStorage.getItem("volumeFlowRate");
	const circulateData = state.circulateState.data.map(dataObj => {
            var actualFlow = volumeFlowRate(dataObj.actualFlowRate).from('m3/s').to(volumeFlowRateUom);
            var targetFlow = volumeFlowRate(dataObj.targetFlowRate).from('m3/s').to(volumeFlowRateUom);
	    var minFlow = volumeFlowRate(dataObj.minFlowRate).from('m3/s').to(volumeFlowRateUom);
	    var maxFlow = volumeFlowRate(dataObj.maxFlowRate).from('m3/s').to(volumeFlowRateUom);
	    
	    return {
		millisecondsEpochUTC: dataObj.millisecondsEpochUTC,
		actual: actualFlow.value,
		target: targetFlow.value,
		min: minFlow.value,
		max: maxFlow.value,
	    };
	});
	params = {
	    title: 'Flow',
	    dataType: 'withDateAndTargetFlow',
	    data: circulateData,
	};
  } else if (ownProps.type === 'Standpipe Pressure') {
    params = {
      title: 'Standpipe Pressure',
      data: state.circulateState.data,
      dataType: 'dateActualMaxMin',
    };
  }
    
  params.drilling = state.rigObjective.objective === 4;
  return params;
};

const mapDispatchToProps = function (dispatch) {
  return {};
};

const CirculateGraphContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DygraphLineGraph);

export default CirculateGraphContainer;
