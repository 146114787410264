import React from 'react';
import RealTimeDataCard from '../Common/realTimeDataCard';
import { velocity } from "units-converter";

class HoistStateView extends React.Component {
    constructor(props) {
        super(props);

    this.state = {
          displayHoistTrend: false,
      };

      this.toggleHoistTrend = this.toggleHoistTrend.bind(this);
  }

  componentWillUnmount() {
    if (this.props.socket) {
      this.props.socket.off("hoistState");
      }
  }

  toggleHoistTrend(event, value) {
   let displayTrends = {displayHoistTrend: !this.props.displayHoistTrend};
   this.props.toggleHoistTrend(displayTrends);
  }

  render() {
    if (typeof this.props.actual === 'undefined') {
       return (
         <React.Fragment/>
     )}

    const velocityUom = localStorage.getItem("velocity");
    var actual = velocity(this.props.actual).from('m/s').to(velocityUom);
    var target = velocity(this.props.target).from('m/s').to(velocityUom);
    var min = velocity(this.props.min).from('m/s').to(velocityUom);
    var max = velocity(this.props.max).from('m/s').to(velocityUom);

    return (
        <React.Fragment>
	      <RealTimeDataCard title="Hoisting"
	                        target={Number.parseFloat(target.value).toFixed()}
				value={Number.parseFloat(actual.value).toFixed()}
				min={Number.parseFloat(min.value).toFixed()}
			        max={Number.parseFloat(max.value).toFixed()}
			        units={velocityUom}
			        displayTrend={this.props.displayHoistTrend}
                                toggleTrend={this.toggleHoistTrend} />
        </React.Fragment>

    );
  }
}

export default HoistStateView;
