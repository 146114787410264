import io from "socket.io-client";
//import {newDrillingCalibrationData, newDrillingCalibrationRequest, } from './autotunerData';

export const RESET_STORE = 'RESET_STORE';
//export const SOCKET_ON_AUTOTUNER_TUNING_STATE = 'SOCKET_ON_AUTOTUNER_TUNING_STATE';
export const SOCKET_OPEN_CONNECTION = 'SOCKET_OPEN_CONNECTION';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_CONNECT_ERROR = 'SOCKET_CONNECT_ERROR';
export const SOCKET_RECONNECTED = 'SOCKET_RECONNECTED';
export const SOCKET_RECONNECTING = 'SOCKET_RECONNECTING';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
export const SOCKET_CLOSE_CONNECTION = 'SOCKET_CLOSE_CONNECTION';
export const SOCKET_EMIT_ROPTIMIZER_CONFIGURATION_REQUEST = 'SOCKET_EMIT_ROPTIMIZER_CONFIGURATION_REQUEST'
export const SOCKET_ON_CIRCULATE_STATE = 'SOCKET_ON_CIRCULATE_STATE';
export const SOCKET_ON_DRILL_REQUEST = 'SOCKET_ON_DRILL_REQUEST';
export const SOCKET_ON_DRILLING_LIMITS = 'SOCKET_ON_DRILLING_LIMITS';
export const SOCKET_ON_HOIST_STATE = 'SOCKET_ON_HOIST_STATE';
export const SOCKET_ON_DRILLING_METRICS = 'SOCKET_ON_DRILLING_METRICS';
export const SOCKET_ON_DRILLING_PERFORMANCE = 'SOCKET_ON_DRILLING_PERFORMANCE';
export const SOCKET_ON_DRILL_STATE = 'SOCKET_ON_DRILL_STATE';
export const SOCKET_ON_HISTORICAL_DATA = 'SOCKET_ON_HISTORICAL';
export const SOCKET_ON_MODEL_STATE = 'SOCKET_ON_MODEL_STATE';
export const SOCKET_ON_NEW_RIG_OBJECTIVE = 'SOCKET_ON_NEW_RIG_OBJECTIVE';
export const SOCKET_ON_ROPTIMIZER_CONFIGURATION = 'SOCKET_ON_ROPTIMIZER_CONFIGURATION';
export const SOCKET_ON_ROPTIMIZER_STEADY_STATE = 'SOCKET_ON_ROPTIMIZER_STEADY_STATE';
export const SOCKET_ON_ROTATE_REQUEST = 'SOCKET_ON_ROTATE_REQUEST';
export const SOCKET_ON_ROTATE_STATE = 'SOCKET_ON_ROTATE_STATE';
export const SOCKET_ON_WELLBORE_STATE = 'SOCKET_ON_WELLBORE_STATE';
export const STOP_ALL_SOCKET_LISTENERS = 'STOP_ALL_SOCKET_LISTENERS';
export const SOCKET_ON_RESOURCES = 'SOCKET_ON_RESOURCES';

export const SOCKET_ON_ERASE_OBJECTIVE_DATA = 'SOCKET_ON_ERASE_OBJECTIVE_DATA';
export const SOCKET_ON_ERASE_VALID_OBJECTIVES = 'SOCKET_ON_ERASE_VALID_OBJECTIVES';
export const SOCKET_ON_OBJECTIVE_DATA = 'SOCKET_ON_OBJECTIVE_DATA';
export const SOCKET_ON_VALID_OBJECTIVES = 'SOCKET_ON_VALID_OBJECTIVES';

export const SET_DISPLAY_TRENDS = 'SET_DISPLAY_TRENDS';
export const DISPLAY_HISTORICAL_DATA = 'DISPLAY_HISTORICAL_DATA';

export function initializeStore() {
  return {
    type: RESET_STORE,
  }
};

export function eraseObjectiveData() {
  return {
    type: SOCKET_ON_ERASE_OBJECTIVE_DATA,
  }
};

/*export function newAutoTunerTuningState(autoTunerTuningState) {
  return {
    type: SOCKET_ON_AUTOTUNER_TUNING_STATE,
    autoTunerTuningState,
  }
};*/

export function eraseValidObjectives() {
  return {
    type: SOCKET_ON_ERASE_VALID_OBJECTIVES,
  }
};

export function newCirculateState(circulateState) {
  return {
    type: SOCKET_ON_CIRCULATE_STATE,
    circulateState,
  }
};

export function newDrillRequest(drillRequest) {
  return {
    type: SOCKET_ON_DRILL_REQUEST,
    drillRequest,
  }
};

export function newDrillingLimits(drillingLimits) {
  return {
    type: SOCKET_ON_DRILLING_LIMITS,
    drillingLimits,
  };
};

export function newDrillState(drillState) {
  return {
    type: SOCKET_ON_DRILL_STATE,
    drillState,
  }
};

export function newDrillingMetrics(drillingMetrics) {
  return {
    type: SOCKET_ON_DRILLING_METRICS,
    drillingMetrics,
  }
};

export function newDrillingPerformance(drillingPerformance) {
  return {
    type: SOCKET_ON_DRILLING_PERFORMANCE,
    drillingPerformance,
  }
};

export function newHistoricalData(historicalData) {
  return {
    type: SOCKET_ON_HISTORICAL_DATA,
    historicalData,
  }
};

export function newModelState(modelState) {
  return {
    type: SOCKET_ON_MODEL_STATE,
    modelState,
  }
};

export function newObjectiveData(objectiveData) {
  return {
    type: SOCKET_ON_OBJECTIVE_DATA,
    objectiveData,
  }
};

export function newRigObjective(rigObjective) {
  return {
    type: SOCKET_ON_NEW_RIG_OBJECTIVE,
    rigObjective,
  }
};

export function newResources(resources) {
  return {
    type: SOCKET_ON_RESOURCES,
    resources,
  };
};

export function newRoptimizerConfiguration (roptimizerData) {
  return {
    type: SOCKET_ON_ROPTIMIZER_CONFIGURATION,
    roptimizerData: roptimizerData,
  };
};

export function newRoptimizerSteadyState (roptimizerData) {
  return {
    type: SOCKET_ON_ROPTIMIZER_STEADY_STATE,
    roptimizerData: roptimizerData,
  };
};

export function newRotateRequest(rotateRequest) {
  return {
    type: SOCKET_ON_ROTATE_REQUEST,
    rotateRequest,
  }
};

export function newHoistState(hoistState) {
  return {
    type: SOCKET_ON_HOIST_STATE,
    hoistState,
  }
};

export function newRotateState(rotateState) {
  return {
    type: SOCKET_ON_ROTATE_STATE,
    rotateState,
  }
};

export function newWellboreState(wellboreState) {
  return {
    type: SOCKET_ON_WELLBORE_STATE,
    wellboreState,
  }
};

export function newValidObjectives(validObjectives) {
  return {
    type: SOCKET_ON_VALID_OBJECTIVES,
    validObjectives,
  }
};

export function openConnection(selection) {
    return dispatch => {
	let apiUrl = process.env.REACT_APP_REST_API_LOCATION + ':' + selection.value;
	let socket = io(apiUrl, {upgrade: false, transports: ['websocket'], });
	console.log("REDUX Action: Initiate Connection - " + apiUrl);
        socket.on('connect', () => {
	    dispatch(socketConnected(socket));
	    dispatch(registerSocketEvents(socket));
	});
        socket.on('connect_error', () => {
	    dispatch(socketConnectError(socket));
	});
        socket.on('connect_timeout', () => {
	    dispatch(socketConnectError(socket));
	});
        socket.on('reconnect', () => {
	    dispatch(socketReconnected(socket));
	});
        socket.on('reconnect_attempt', () => {
	    dispatch(socketReconnecting(socket));
	});
        socket.on('disconnect', () => {
	    console.log("REDUX Action: Unregister Socket Events");
	    socket.removeAllListeners();
	    dispatch(socketDisconnected(socket));
	});
    }
}

export function closeConnection(socket) {
    console.log("REDUX Action: Unregister Socket Events");
    socket.removeAllListeners();
    console.log("REDUX Action: Close Conection");
    socket.close();
    socket.disconnect();

    return {
	type: SOCKET_CLOSE_CONNECTION,
	socket: null,
  }
}

export function registerSocketEvents(socket) {
    console.log("REDUX Action: Register Socket Events");
    return dispatch => {
	socket.on('rigObjective', (rigObjective) => {
	    dispatch(newRigObjective(rigObjective));
	});
	socket.on('drillingLimits', (drillingLimits) => {
	    dispatch(newDrillingLimits(drillingLimits));
	});
	socket.on('resources', (resources) => {
	    dispatch(newResources(resources));
	});
	socket.on('roptimizerConfiguration', (roptimizerConfig) => {
	    dispatch(newRoptimizerConfiguration(roptimizerConfig));
	});
/*	socket.on('autoTunerTuningState', (autoTunerTuningState) => {
	    dispatch(newAutoTunerTuningState(autoTunerTuningState));
	});*/
	socket.on('modelState', (modelState) => {
	    dispatch(newModelState(modelState));
	});
/*	socket.on('drillingCalibrationState', (drillingCalibrationData) => {
	    dispatch(newDrillingCalibrationData(drillingCalibrationData));
	});
	socket.on('drillingCalibrationRequest', (drillingCalibrationRequest) => {
	    dispatch(newDrillingCalibrationRequest(drillingCalibrationRequest));
	});*/
        socket.on('wellboreState', (wellboreState) => {
            dispatch(newWellboreState(wellboreState));
	});
	socket.on('circulateState', (circulateState) => {
	    dispatch(newCirculateState(circulateState));
        });
	socket.on('drillState', (drillState) => {
	    dispatch(newDrillState(drillState));
        });
	socket.on('hoistState', (hoistState) => {
	    dispatch(newHoistState(hoistState));
        });
	socket.on('rotateState', (rotateState) => {
	    dispatch(newRotateState(rotateState));
        });
	socket.on('drillRequest', (drillRequest) => {
	    dispatch(newDrillRequest(drillRequest));
	});
	socket.on('allHistoricalData', (historicalData) => {
	    dispatch(newHistoricalData(historicalData));
	});
	socket.on('rotateRequest', (rotateRequest) => {
	    dispatch(newRotateRequest(rotateRequest));
	});
	socket.on('validObjectives', (validObjectives) => {
	    dispatch(newValidObjectives(validObjectives));
	});

	socket.emit('getRigObjective');
	socket.emit('getRoptimizerConfiguration');
//	socket.emit('getAutoTunerConfiguration');
	socket.emit('getValidObjectives');
	socket.emit('getDrillingLimits');
	socket.emit('getWellboreData');
    }
}

export function socketConnected(socket) {
    console.log("REDUX Action: Connection Establised");
    return {
	type: SOCKET_CONNECTED,
	socket,
    }
}

export function socketConnectError(socket) {
    console.log("REDUX Action: Connection Error");
    return {
	type: SOCKET_CONNECT_ERROR,
	socket,
    }
}

export function socketReconnected(socket) {
    console.log("REDUX Action: Socket Reconnected");
    return {
	type: SOCKET_RECONNECTED,
	socket,
    }
}

export function socketReconnecting(socket) {
    console.log("REDUX Action: Socket Reconnecting");
    return {
	type: SOCKET_RECONNECTING,
	socket,
    }
}

export function socketDisconnected(socket) {
    console.log("REDUX Action: Socket Disconnected");
    return {
	type: SOCKET_DISCONNECTED,
	socket,
    }
}

export function sendRoptimizerConfigurationRequest(data) {
  return {
    type: SOCKET_EMIT_ROPTIMIZER_CONFIGURATION_REQUEST,
    data,
  };
};

export function setDisplayTrends(trend, display) {
  return {
      type: SET_DISPLAY_TRENDS,
      trend,
      display,
  };
}

export function setDisplayHistoricalData(data) {
  return {
    type: DISPLAY_HISTORICAL_DATA,
    displayHistoricalData: data,
  };
}
