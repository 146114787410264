import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import AutoTunerDialog from '../AutoTuner/autoTunerDialogNew';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CloseIcon from '@material-ui/icons/Close';
import ColorLensIcon from '@material-ui/icons/ColorLensOutlined';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Select from '@material-ui/core/Select';
import SettingsIcon from '@material-ui/icons/Settings';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import TuneIcon from '@material-ui/icons/Tune';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { velocity } from "units-converter";
import { angularVelocity } from "units-converter";
import { volumeFlowRate } from "units-converter";
import { length } from "units-converter";
import { pressure } from "units-converter";
import { torque } from "units-converter";
import { force } from "units-converter";
import { withStyles } from "@material-ui/core/styles";

const StyledMenu = withStyles({
    paper: {
	border: '1px solid #d3d4d5',
    },
})((props) => (
	<Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
	vertical: 'bottom',
	horizontal: 'center',
    }}
    transformOrigin={{
	vertical: 'top',
	horizontal: 'center',
    }}
    {...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);


const useStyles = makeStyles((theme) => ({
    appBar: {
	position: 'relative',
    },
    title: {
	marginLeft: theme.spacing(2),
	flex: 1,
    },
    container: {
	display: 'flex',
	flexWrap: 'wrap',
    },
    formControl: {
	margin: theme.spacing(1),
	minWidth: 120,
    },
    selectEmpty: {
	marginTop: theme.spacing(2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserConfigDialog() {
    const classes = useStyles();
    const [open, setOpenUserDialog] = React.useState(false);
    const [lengthUom, setLengthUom] = React.useState(localStorage.getItem('length'));
    const [pressureUom, setPressureUom] = React.useState(localStorage.getItem('pressure'));
    const [angularVelocityUom, setAngularVelocityUom] = React.useState(localStorage.getItem('angularVelocity'));
    const [velocityUom, setVelocityUom] = React.useState(localStorage.getItem('velocity'));
    const [volumeFlowRateUom, setVolumeFlowRateUom] = React.useState(localStorage.getItem('volumeFlowRate'));
    const [forceUom, setForceUom] = React.useState(localStorage.getItem('force'));
    const [torqueUom, setTorqueUom] = React.useState(localStorage.getItem('torque'));
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleChangeLengthUom = (event) => {
	setLengthUom(event.target.value);
        localStorage.setItem('length', event.target.value);
    };

    const handleChangePressureUom = (event) => {
	setPressureUom(event.target.value);
        localStorage.setItem('pressure', event.target.value);
    };

    const handleChangeVelocityUom = (event) => {
	setVelocityUom(event.target.value);
        localStorage.setItem('velocity', event.target.value);
    };

    const handleChangeVolumeFlowRateUom = (event) => {
	setVolumeFlowRateUom(event.target.value);
        localStorage.setItem('volumeFlowRate', event.target.value);
    };

    const handleChangeAngularVelocityUom = (event) => {
	setAngularVelocityUom(event.target.value);
        localStorage.setItem('angularVelocity', event.target.value);
    };

    const handleChangeForceUom = (event) => {
	setForceUom(event.target.value);
        localStorage.setItem('force', event.target.value);
    };

    const handleChangeTorqueUom = (event) => {
	setTorqueUom(event.target.value);
        localStorage.setItem('torque', event.target.value);
    };

    const handleOpen = () => {
	setOpenUserDialog(true);
    };

    const handleClose = () => {
	setOpenUserDialog(false);
    };

    const handleClickEl = (event) => {
	setAnchorEl(event.currentTarget);
    };

    const handleCloseEl = () => {
	setAnchorEl(null);
    };

    return (
	<div>
            <Tooltip title="User Settings" placement="top">
                <IconButton color="inherit"
                            aria-label="User Configuration Settings"
                            onClick={handleClickEl} >
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
	    <StyledMenu id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseEl} >
              <StyledMenuItem onClick={AutoTunerDialog}>
                    <ListItemIcon>
                        <TuneIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="AutoTuner" />
                </StyledMenuItem>
                <StyledMenuItem>
                    <ListItemIcon>
                        <ColorLensIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Theme" />
                </StyledMenuItem>
            <StyledMenuItem onClick={handleOpen}>
                    <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="User Settings" />
                </StyledMenuItem>
	    </StyledMenu>
	    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            User Configuration
                        </Typography>
                    </Toolbar>
            </AppBar>
	            <Card >
	                <CardHeader title="Units"
 	                            titleTypographyProps={{ align: "center", variant: "h6"}}  />
                <CardContent>
	    <form className={classes.container}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="velocity-uom-label">Velocity</InputLabel>
                        <Select id="velocity-uom"
	                        labelId="velocity-label-uom"
                                value={velocityUom}
                                onChange={handleChangeVelocityUom} >
			    {
				velocity().list().map((unitOfMeasure) => (
				    <MenuItem value={unitOfMeasure.unit}>{unitOfMeasure.unit}</MenuItem>
   			        ))
			    }
	                </Select>
	         </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="angularVelocity-uom-label">Angular Velocity</InputLabel>
                        <Select id="angularVelocity-uom"
	                        labelId="angularVelocity-label-uom"
                                value={angularVelocityUom}
                                onChange={handleChangeAngularVelocityUom} >
			    {
				angularVelocity().list().map((unitOfMeasure) => (
				    <MenuItem value={unitOfMeasure.unit}>{unitOfMeasure.unit}</MenuItem>
   			        ))
			    }
	                </Select>
	         </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="force-uom-label">Force</InputLabel>
                        <Select id="force-uom"
	                        labelId="force-label-uom"
                                value={forceUom}
                                onChange={handleChangeForceUom} >
			    {
				force().list().map((unitOfMeasure) => (
				    <MenuItem value={unitOfMeasure.unit}>{unitOfMeasure.unit}</MenuItem>
   			        ))
			    }
	                </Select>
	         </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="torque-uom-label">Torque</InputLabel>
                        <Select id="torque-uom"
	                        labelId="torque-label-uom"
                                value={torqueUom}
                                onChange={handleChangeTorqueUom} >
			    {
				torque().list().map((unitOfMeasure) => (
				    <MenuItem value={unitOfMeasure.unit}>{unitOfMeasure.unit}</MenuItem>
   			        ))
			    }
	                </Select>
	         </FormControl>
	         <FormControl variant="outlined" className={classes.formControl}>
	             <InputLabel >Length</InputLabel>
                     <Select value={lengthUom}
	                     onChange={handleChangeLengthUom} >
			    {
			        length().list().map((unitOfMeasure) => (
				    <MenuItem value={unitOfMeasure.unit}>{unitOfMeasure.unit}</MenuItem>
   			        ))
			    }
	             </Select>
	         </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="volumeFlowRate-uom-label">Volumetric Flow</InputLabel>
                        <Select id="volumeFlowRate-uom"
	                        labelId="volumeFlowRate-label-uom"
                                value={volumeFlowRateUom}
                                onChange={handleChangeVolumeFlowRateUom} >
			    {
				volumeFlowRate().list().map((unitOfMeasure) => (
				    <MenuItem value={unitOfMeasure.unit}>{unitOfMeasure.unit}</MenuItem>
   			        ))
			    }
	                </Select>
	         </FormControl>
	         <FormControl variant="outlined" className={classes.formControl}>
	             <InputLabel >Pressure</InputLabel>
                                        <Select id="pressure"
                                                value={pressureUom}
	                                        onChange={handleChangePressureUom} >
			    {
			        pressure().list().map((unitOfMeasure) => (
				    <MenuItem value={unitOfMeasure.unit}>{unitOfMeasure.unit}</MenuItem>
   			        ))
			    }
	</Select>
	    </FormControl>
	    </form>
                </CardContent>
            </Card>
	    </Dialog>
	</div>
    );
}
