import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';

import { createInputWithTooltip,
         createInputWithTooltipUnit,
	 createSubmitButton  } from '../Common/CreateInputsHelperMethods';

class ModelState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        pipeInnerDiameter: props.modelState.pipeInnerDiameter,
        pipeOuterDiameter: props.modelState.pipeOuterDiameter,
        slopeFilter: props.modelState.slopeFilter,
        tauMax: props.modelState.tauMax,
        tauMin: props.modelState.tauMin,
        tauMultiplier: props.modelState.tauMultiplier,
        maxDeviation: props.modelState.maxDeviation,
        minInterval: props.modelState.minInterval,
    };

    this.updateInput = this.updateInput.bind(this);
    this.configurationRequest = this.configurationRequest.bind(this,);
  }

  updateInput(name) {
    let that = this;
    return function (event) {
      that.setState({ [name]: event.target.value });
    };
  }

  configurationRequest() {
    const requestData = {};
    requestData.pipeInnerDiameter = this.state.pipeInnerDiameter;
    requestData.pipeOuterDiameter = this.state.pipeOuterDiameter;
    requestData.slopeFilter = this.state.slopeFilter;
    requestData.tauMax = this.state.tauMax;
    requestData.tauMin = this.state.tauMin;
    requestData.tauMultiplier = this.state.tauMultiplier;
    requestData.maxDeviation = this.state.maxDeviation;
    requestData.minInterval = this.state.minInterval;

    this.props.configurationRequest("ModelState", requestData);
  }


  render() {
    const lengthUom = "in"
    var modelState = this.props.modelState;

    return (
      <Grid container xs={6}
        id="modal-content-tab-content-at-model-state"
        justify="center"
        spacing={2} >
        <Grid item>
          <Card className="configuration-request-card" variant="outlined">
            <CardHeader title="Model State" />
            <CardContent>
              <Grid container spacing={12}>
 	        <Grid item xs={6}>
                  {createInputWithTooltipUnit(
                   'pipeInnerDiameter',
                   'Inner Diameter',
		   lengthUom,
		   modelState.pipeInnerDiameter,
                   this.state.pipeInnerDiameter,
                   this.updateInput,
                  )}
	        </Grid>
 	        <Grid item xs={6}>
                  {createInputWithTooltipUnit(
                   'pipeOuterDiameter',
                   'Outer Diameter',
		   lengthUom,
		   modelState.pipeOuterDiameter,
                   this.state.pipeOuterDiameter,
                   this.updateInput,
                  )}
	        </Grid>
 	        <Grid item xs={6}>
                  {createInputWithTooltip(
                   'tauMin',
                   'Tau Min',
                   modelState.tauMin,
                   this.state.tauMin,
                   this.updateInput,
                  )}
	        </Grid>
 	        <Grid item xs={6}>
                  {createInputWithTooltip(
                   'tauMax',
                   'Tau Max',
                   modelState.tauMax,
                   this.state.tauMax,
                   this.updateInput,
                 )}
	        </Grid>
 	        <Grid item xs={6}>
                  {createInputWithTooltip(
                   'tauMultiplier',
                   'Tau Multiplier',
                   modelState.tauMultiplier,
                   this.state.tauMultiplier,
                   this.updateInput,
                  )}
	        </Grid>
 	        <Grid item xs={6}>
                  {createInputWithTooltip(
                   'maxDeviation',
                   'Max Deviation',
                   modelState.maxDeviation,
                   this.state.maxDeviation,
                   this.updateInput,
                  )}
	        </Grid>
 	        <Grid item xs={6}>
                  {createInputWithTooltip(
                   'minInterval',
                   'Min Interval',
                   modelState.minInterval,
                   this.state.minInterval,
                   this.updateInput,
                  )}
	        </Grid>
 	        <Grid item xs={6}>
                  {createInputWithTooltipUnit(
                   'slopeFilter',
                   'Slope Filter',
		   'sec',
                   modelState.slopeFilter,
                   this.state.slopeFilter,
                   this.updateInput,
                  )}
	        </Grid>
 	        <Grid item xs={12} align="center">
	          {createSubmitButton(
		  'UpdateModelState',
		  this.configurationRequest)}
		</Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default ModelState;
