import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_ROPTIMIZER_STEADY_STATE,
  SOCKET_ON_HISTORICAL_DATA,
} from '../actions/data';

export default function roptimizerSteadyState(state, action) {
  if (typeof state === 'undefined') {
    return {
	torqueSteadyState: false,
	ropSteadyState: false,
	wobSteadyState: false,
	rpmSteadyState: false,

	bitWear: 0,
	mse: 0,
	power: 0,

	bitWearData: [],
	mseData: [],
	powerData: [],
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
    case SOCKET_ON_ROPTIMIZER_STEADY_STATE:
      newState.torqueSteadyState = action.roptimizerData.torqueSteadyState;
      newState.ropSteadyState = action.roptimizerData.ropSteadyState;
      newState.wobSteadyState = action.roptimizerData.wobSteadyState;
      newState.rpmSteadyState = action.roptimizerData.rpmSteadyState;

      newState.bitWear = action.roptimizerData.bitWear;
      newState.mse = action.roptimizerData.mse;
      newState.power = action.roptimizerData.power;

      if (newState.bitWearData.length < process.env.REACT_APP_DATA_POINT_LIMIT) {
        newState.bitWearData = newState.bitWearData.concat([action.roptimizerData.bitWearPoint]);
      } else {
        newState.bitWearData.push(action.roptimizerData.bitWearPoint);
        newState.bitWearData = newState.bitWearData.slice(1);
      }

      if (newState.mseData.length < process.env.REACT_APP_DATA_POINT_LIMIT) {
        newState.mseData = newState.mseData.concat([action.roptimizerData.msePoint]);
      } else {
        newState.mseData.push(action.roptimizerData.msePoint);
        newState.mseData = newState.mseData.slice(1);
      }

      if (newState.powerData.length < process.env.REACT_APP_DATA_POINT_LIMIT) {
        newState.powerData = newState.powerData.concat([action.roptimizerData.powerPoint]);
      } else {
        newState.powerData.push(action.roptimizerData.powerPoint);
        newState.powerData = newState.powerData.slice(1);
      }
      return newState;
    case SOCKET_ON_HISTORICAL_DATA:
      newState.bitWearData = action.historicalData.historicalRoptimizerBitWear || [];
      newState.mseData = action.historicalData.historicalRoptimizerMSE || [];
      newState.powerData = action.historicalData.historicalRoptimizerPower || [];

      const lastRoptimizerSteadyState = action.historicalData.lastRoptimizerSteadyState;
      if (Object.keys(lastRoptimizerSteadyState).length !== 0 && lastRoptimizerSteadyState.constructor === Object) {
        newState.torqueSteadyState = lastRoptimizerSteadyState.torqueSteadyState;
        newState.ropSteadyState = lastRoptimizerSteadyState.ropSteadyState;
        newState.wobSteadyState = lastRoptimizerSteadyState.wobSteadyState;
        newState.rpmSteadyState = lastRoptimizerSteadyState.rpmSteadyState;

        newState.bitWear = lastRoptimizerSteadyState.bitWear;
        newState.mse = lastRoptimizerSteadyState.mse;
        newState.power = lastRoptimizerSteadyState.power;
      } else {
        newState.torqueSteadyState = null;
        newState.ropSteadyState = null;
        newState.wobSteadyState = null;
        newState.rpmSteadyState = null;

        newState.bitWear = 0;
        newState.mse = 0;
        newState.power = 0;

        newState.bitWearData = [];
        newState.mseData = [];
        newState.powerData = [];
      }
      return newState;
    default:
      return state;
  }
}
