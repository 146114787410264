import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress  from '@material-ui/core/CircularProgress';
import CirculateGraphContainer from '../CirculateState/circulateGraphContainer';
import CirculateStateViewContainer from '../CirculateState/circulateStateViewContainer';
import DrillingCalibrationApplicationContainer from '../AutoTunerApplication/drillingCalibrationCardContainer';
import DrillStateViewContainer from '../DrillState/drillStateViewContainer';
import DrillingMetricsContainer from '../DrillingMetrics/drillingMetricsContainer';
import DygraphLineGraphContainer from '../LineGraph/dygraphLineGraphContainer';
import Grid from '@material-ui/core/Grid';
import HoistStateViewContainer from '../HoistState/hoistStateViewContainer';
import ObjectiveViewContainer from '../Objective/objectiveViewContainer';
import PressureViewContainer from '../WellControl/pressureViewContainer';
import React from 'react';
import RoptimizerApplicationContainer from '../RoptimizerApplication/roptimizerCardContainer';
import RotateStateViewContainer from '../RotateState/rotateStateViewContainer';
import Typography from '@material-ui/core/Typography';
import WellboreViewContainer from '../WellboreState/wellboreViewContainer';
import './overView.css';

class OverviewView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
  }
  
  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  componentWillUnmount() {
      console.log("overviewView:componentWillUnmount");
  }

  render() {
    if (this.props.connected === false ) {
       return (
         <React.Fragment>
       	   <Backdrop open={!this.props.connected}>
	   <Typography>Establishing Remote Connection...</Typography>
	      <CircularProgress color="inherit" />
	   </Backdrop>
         </React.Fragment>
       )}

    if (this.props.rigObjective.id === null ) {
       return (
         <React.Fragment>
       	   <Backdrop open={true}>
	   <Typography>Initializing Data Streams...</Typography>
	      <CircularProgress color="inherit" />
	   </Backdrop>
         </React.Fragment>
       )}

    return (
      <Grid container xs={12} direction="row" alignItems="stretch" spacing={1} >
            <ObjectiveViewContainer />
            <WellboreViewContainer />
            <PressureViewContainer />
        {((this.props.drilling === true) ||
	  (this.props.sliding === true)) ? (
	  <React.Fragment>
	    <Grid item xs={12} sm={6} md={3} lg={3}>
	      <DrillingMetricsContainer />
	    </Grid>
	  </React.Fragment>
	) : null}

	 <Grid container xs={6} direction="row" spacing={1} alignItems="center">
	    <Grid item xs={4}>
                <RotateStateViewContainer />
	    </Grid>
	    <Grid item xs={4}>
                <CirculateStateViewContainer />
	    </Grid>
	    <Grid item xs={4}>
                <HoistStateViewContainer />
	    </Grid>
	</Grid>
        <Grid item xs={6} alignItems="stretch" >
            <DrillStateViewContainer />
        </Grid>

	{this.props.displayRotateTrend === true ? (
          <Grid item xs={6} >
            <DygraphLineGraphContainer type="RPM" />
          </Grid>
	) : null}
	{this.props.displayFlowTrend === true ? (
          <Grid item xs={6} >
            <CirculateGraphContainer type="Flow" />
          </Grid>
	) : null}
	{((this.props.drilling === true) && (this.props.displayRopTrend === true)) ? (
          <Grid item xs={6} >
            <DygraphLineGraphContainer type="ROP" />
          </Grid>
	) : null}
	{((this.props.drilling === true) && (this.props.displayWobTrend === true)) ? (
          <Grid item xs={6} >
            <DygraphLineGraphContainer type="WOB" />
          </Grid>
	) : null}
	{this.props.displayDiffpTrend === true ? (
          <Grid item xs={6} >
            <DygraphLineGraphContainer type="Differential Pressure" />
          </Grid>
	) : null}
	{this.props.displayTorqueTrend === true ? (
          <Grid item xs={6} >
            <DygraphLineGraphContainer type="Torque" />
          </Grid>
	) : null}
        {this.props.displayMseTrend === true ? (
          <Grid item xs={6} >
            <DygraphLineGraphContainer type="MSE" />
          </Grid>
	) : null}
        {this.props.displayPowerTrend === true ? (
          <Grid item xs={6} >
            <DygraphLineGraphContainer type="Power" />
          </Grid>
	) : null}
        {this.props.displayBitwearTrend === true ? (
          <Grid item xs={6} >
            <DygraphLineGraphContainer type="Bit Wear" />
          </Grid>
	) : null}
      </Grid>
    );
  }
}

export default OverviewView;
