import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from "d3-format"


export default function DigitalDisplay(props) {

    return (
      <Grid item xs>
        <Card >
          <CardHeader title={props.title}
 		      titleTypographyProps={{ align: "center", variant: "subtitle1"}}
	              subheader={format(",")(props.value)}
		      subheaderTypographyProps={{ align: "center", }}
		      action={props.icon} />
	{props.trend === true ? (
	<CardActions disableSpacing>
	    <Tooltip title="Historical Trend" placement="bottom">
	      <IconButton size="small"
		  	  onClick={props.toggleTrend}
	                  color={props.displayTrend === true ? "secondary": "inherit"} >
                <TimelineIcon fontSize="small" />
	      </IconButton>
            </Tooltip>
	</CardActions>
	) : null}
        </Card>
      </Grid>
    );
}

