import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_DRILL_REQUEST,
  SOCKET_ON_HISTORICAL_DATA,
} from '../actions/data';

export default function drillRequestData(state, action) {
  if (typeof state === 'undefined') {
    return {
	validData: false,
	ropTarget: 0,
	wobTarget: 0,
	torqueTarget: 0,
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
    case SOCKET_ON_DRILL_REQUEST:
      newState.ropTarget = action.drillRequest.ropTarget;
      newState.wobTarget = action.drillRequest.wobTarget;
      newState.torqueTarget = action.drillRequest.torqeTarget;
      newState.validData = true;
      return newState;
    case SOCKET_ON_HISTORICAL_DATA:
      const lastDrillRequestData = action.historicalData.lastDrillRequestData;
      if (Object.keys(lastDrillRequestData).length !== 0 && lastDrillRequestData.constructor === Object) {
        newState.ropTarget = lastDrillRequestData.ropTarget;
        newState.wobTarget = lastDrillRequestData.wobTarget;
	newState.torqueTarget = lastDrillRequestData.torqeTarget;
      } else {
        newState.ropTarget = -9999.25;
        newState.wobTarget = -9999.25;
        newState.torqueTarget = -9999.25;
      }
      return newState;
    default:
      return state;
  }
}
