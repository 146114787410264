import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import AutoTunerViewContainer from './AutoTunerViewContainer';
import TuneIcon from '@material-ui/icons/Tune';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appBar: {
	position: 'relative',
    },
    title: {
	marginLeft: theme.spacing(2),
	flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AutoTunerDialog() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
	setOpen(true);
    };

    const handleClose = () => {
	setOpen(false);
    };

    return (
	<div>
            <Tooltip title="AutoTuner Configuration" placement="top">
                <IconButton color="inherit"
                            aria-label="Change Color Theme Toggle"
                            onClick={handleClickOpen}>
                <TuneIcon />
                </IconButton>
            </Tooltip>
	    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            AutoTuner Configuration
                        </Typography>
                    </Toolbar>
            </AppBar>
            <AutoTunerViewContainer />
		    
	    </Dialog>
	</div>
    );
}
