import { connect } from 'react-redux';
import RotateStateView from './rotateStateView';
import { setDisplayTrends } from '../../redux/actions/data';

const mapStateToProps = function mapStateToProps(state) {
    return {
	socket: state.connection.socket,
	drilling: state.rigObjective.objective === 4,
	validData: state.rotateState.valid,
	target: state.rotateState.target,
	actual: state.rotateState.actual,
	min: state.limits.rotateMin,
	max: state.limits.rotateMax,
	displayTrend: state.displayTrends["rotate"].display,
  };
};

const mapDispatchToProps = function (dispatch) {
    return {
	toggleRotateTrend(trend, display) {
            dispatch(setDisplayTrends(trend, display));
	},
    }
};

const RotateStateViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RotateStateView);

export default RotateStateViewContainer;
