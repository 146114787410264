import { combineReducers } from 'redux';

import activeRigs from './activeRigs';
import auth from './auth';
//import autoTunerStateData from './autoTunerStateData';
import connection from './connection';
import circulateState from './circulateStateData';
import drillRequestData from './drillRequestData';
import drillingCalibration from './drillingCalibration';
import drillingCalibrationRequest from './drillingCalibrationRequest';
import drillState from './drillStateData';
import drillingMetrics from './drillingMetricsData';
import drillingPerformanceData from './drillingPerformanceData';
import hoistState from './hoistStateData';
import limits from './limits';
import modelStateData from './modelStateData';
import objectiveData from './objectiveData';
import rigObjective from './rigObjective';
import resources from './resources';
import resourcesCpu from './resourcesCpu';
import resourcesThreads from './resourcesThreads';
import rotateRequestData from './rotateRequestData';
import rotateState from './rotateStateData';
import roptimizerConfiguration from './roptimizerConfiguration';
import roptimizerSteadyState from './roptimizerSteadyState';
import wellboreState from './wellboreStateData';
import displayTrends from './displayTrends';
import { RESET_STORE } from '../actions/data';

const appReducer = combineReducers({
 activeRigs,
 auth,
// autoTunerStateData,
 circulateState,
 connection,
 displayTrends,
 drillRequestData,
 drillingCalibration,
 drillingCalibrationRequest,
 drillState,
 drillingMetrics,
 drillingPerformanceData,
 hoistState,
 limits,
 modelStateData,
 objectiveData,
 rigObjective,
 resources,
 resourcesCpu,
 resourcesThreads,
 rotateRequestData,
 rotateState,
 roptimizerConfiguration,
 roptimizerSteadyState,
 wellboreState,
});


const rootReducer = (state, action) => {
    if (action.type === RESET_STORE) {
	console.log("Reset Store");
	const { auth,
	        activeRigs} = state;
	state = { auth, activeRigs };
    }
    return appReducer(state, action);
}

export default rootReducer;
