import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import DisplayValue from '../Common/displayValue';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { length } from "units-converter";
import './wellboreView.css';

class WellboreView extends React.Component {

  componentDidMount() {
    if (this.props.validData === false) {
        this.props.socket.emit('getWellboreData');
    }
  }
  
  render() {
    const wellboreData = this.props;
    const lengthUom = localStorage.getItem("length");

    if (wellboreData.validData === false) {
       return (
         <React.Fragment/>
     )}

    var actualPosition = length(wellboreData.actualPosition).from('m').to(lengthUom);
    var bitDepth = length(wellboreData.bitDepth).from('m').to(lengthUom);
    var holeDepth = length(wellboreData.holeDepth).from('m').to(lengthUom);
 
    return (
	<Grid item xs={12} sm={6} md={3} lg={3}>
            <Card style={{ height: "200px" }} className = "wellbore-card" > 
	        <CardHeader title="Wellbore"
			    titleTypographyProps={{ align: "center", variant: "h6"}}  />
                <CardContent>
		<Grid item container xs={12}>
		  <Grid item xs={6}>
   	            <Typography align="right">Formation:</Typography>
		  </Grid>
		  <Grid item xs={6}>
		  </Grid>
		  <Grid item xs={6}>
   	            <Typography align="right">Block Height:</Typography>
		  </Grid>
		  <Grid item xs={6}>
		    <DisplayValue value={actualPosition.value} uom={actualPosition.unit} />
		  </Grid>
		  <Grid item xs={6}>
   	            <Typography align="right">Bit Depth:</Typography>
		  </Grid>
		  <Grid item xs={6}>
		    <DisplayValue value={bitDepth.value} uom={bitDepth.unit} />
		  </Grid>
		  <Grid item xs={6}>
   	            <Typography align="right">Hole Depth:</Typography>
		  </Grid>
		  <Grid item xs={6}>
		    <DisplayValue value={holeDepth.value} uom={holeDepth.unit} />
		  </Grid>
	        </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
  }
}

export default WellboreView;
