import { connect } from 'react-redux';
import { newWellboreState } from '../../redux/actions/data';
import WellboreView from './wellboreView';

const mapStateToProps = function mapStateToProps(state, ownProps) {
    return {
	validData: state.wellboreState.validData,
	holeDepth: state.wellboreState.holeDepth,
	bitDepth: state.wellboreState.bitDepth,
	actualPosition: state.hoistState.actualPosition,
	socket: state.connection.socket,
	connected: state.connection.connected,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    watchWellboreData(socket) {
      socket.on('wellboreData', (wellboreData) => {
        dispatch(newWellboreState(wellboreData));
      });
    },
  };
};

const WellboreViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WellboreView);

export default WellboreViewContainer;
