import Dygraph from 'dygraphs';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress  from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Popover,
  TextField,
  Button,
} from '@material-ui/core';
import Error from '@material-ui/icons/Error';
import SettingsIcon from '@material-ui/icons/Settings';

import './linegraph.css';

export default class DygraphLineGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      graph: null,
      isSettingsModalOpen: false,
      yMax: props.max,
      yMin: props.min,
    };

    this.prepData = this.prepData.bind(this);
    this.createGraph = this.createGraph.bind(this);
    this.updateGraph = this.updateGraph.bind(this);

    this.openSettingsModal = this.openSettingsModal.bind(this);
    this.closeSettingsModal = this.closeSettingsModal.bind(this);

    this.changeYMax = this.changeYMax.bind(this);
    this.changeYMin = this.changeYMin.bind(this);
    this.resetYRange = this.resetYRange.bind(this);
    this.applyChanges = this.applyChanges.bind(this);

    this.legendContainerRef = React.createRef();
    this.graphContainerRef = React.createRef();

    this.isUTC =
      this.props.dataType === 'drillStateLineGraph' ||
      this.props.dataType === 'dateMSEMaxMin' ||
      this.props.dataType === 'dateBitWearMaxMin' ||
      this.props.dataType === 'datePowerMaxMin';
  }

  prepData() {
    const that = this;
    if (this.props.dataType === 'withDateAndTarget') {
      return this.sortData(
        this.props.data.map(function (dataObj) {
          return [
            new Date(dataObj.millisecondsEpochUTC),
            dataObj.actual,
            dataObj.max,
            dataObj.min,
            dataObj.target,
          ];
        }),
      );
    } else if (this.props.dataType === 'withDateAndTargetFlow') {
      return this.sortData(
        this.props.data.map(function (dataObj) {
	  return [
            new Date(dataObj.millisecondsEpochUTC),
	    dataObj.actual,
	    dataObj.target,
	    dataObj.min,
	    dataObj.max,
          ];
        }),
      );
    } else if (this.props.dataType === 'withDateAndDepth') {
      return this.sortData(
        this.props.data.map(function (dataObj) {
          return [
            new Date(dataObj.millisecondsEpochUTC),
            dataObj.holeDepth,
            dataObj.measuredDepth,
            dataObj.trueVerticalDepth,
            dataObj.bitDepth,
          ];
        }),
      );
    } else if (this.props.dataType === 'dateActualMaxMin') {
      return this.sortData(
        this.props.data.map(function (dataObj) {
          return [
            new Date(dataObj.millisecondsEpochUTC),
            dataObj.actualStandpipePressure,
            dataObj.maxStandpipePressure,
            dataObj.minStandpipePressure,
          ];
        }),
      );
    } else if (this.props.dataType === 'drillStateLineGraph') {
      return this.sortData(
        this.props.data.map(dataObj => {
          return [
            new Date(dataObj.millisecondsEpochUTC),
            dataObj.actual,
            dataObj.target,
            that.props.min.value,
            that.props.max.value,
          ];
        }),
      );
    } else if (this.props.dataType === 'dateActualMaxMinCPU') {
      return this.sortData(
        this.props.data.map(function (dataObj) {
          return [
            new Date(dataObj.millisecondsEpochUTC),
            dataObj.cpuPercent,
            dataObj.maxCpuPercent,
            dataObj.minCpuPercent,
          ];
        }),
      );
    } else if (this.props.dataType === 'dateActualMaxMinThreads') {
      return this.sortData(
        this.props.data.map(function (dataObj) {
          return [
            new Date(dataObj.millisecondsEpochUTC),
            dataObj.numThreads,
            dataObj.maxNumThreads,
            dataObj.minNumThreads,
          ];
        }),
      );
    } else if (this.props.dataType === 'dateMSEMaxMin') {
      return this.sortData(
        this.props.data.map(function (dataObj) {
          return [
            new Date(dataObj.millisecondsEpochUTC),
            dataObj.mse,
            that.props.max,
            that.props.min,
          ];
        }),
      );
    } else if (this.props.dataType === 'dateBitWearMaxMin') {
      return this.sortData(
        this.props.data.map(function (dataObj) {
          return [
            new Date(dataObj.millisecondsEpochUTC),
            dataObj.bitWear,
            that.props.max,
            that.props.min,
          ];
        }),
      );
    } else if (this.props.dataType === 'datePowerMaxMin') {
      return this.sortData(
        this.props.data.map(function (dataObj) {
          return [
            new Date(dataObj.millisecondsEpochUTC),
            dataObj.power,
            that.props.max,
            that.props.min,
          ];
        }),
      );
    } else if (this.props.dataType === 'rpmLineGraph' ) {
      return this.sortData(
        this.props.data.map(function (dataObj) {
	  return [
	    new Date(dataObj.millisecondsEpochUTC),
            dataObj.actual,
            dataObj.target,
            that.props.min.value,
            that.props.max.value,
          ];
        }),
      );
    }
  }

  sortData(data) {
    return data.sort(function (dataPoint1, dataPoint2) {
      if (dataPoint1[0] > dataPoint2[0]) {
        return 1;
      } else if (dataPoint1[0] < dataPoint2[0]) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  resizeGraph() {
    const width = this.props.graphContainerRef.current.clientWidth;
    const height = this.props.graphContainerRef.current.clientHeight;

    this.state.graph.resize(width * 0.96, height * 0.92);
  }

  createGraph() {
    const data = this.prepData();

    let labels;
    if (this.props.dataType === 'withDateAndTarget') {
      labels = ['Time', 'Actual', 'Max', 'Min', 'Target'];
    } else if (this.props.dataType === 'withDateAndDepth') {
      labels = [
        'Time',
        'Hole Depth',
        'Measured Depth',
        'True Vertical Depth',
        'Bit Depth',
      ];
    } else if (this.props.dataType === 'withDateAndPressure') {
      labels = [
        'Time',
        'Hole Depth',
        'Measured Depth',
        'True Vertical Depth',
        'Bit Depth',
      ];
    } else if (this.props.dataType === 'withDateAndTargetFlow') {
      labels = ['Time', 'Actual', 'Target', 'Min', 'Max'];
    } else if (this.props.dataType === 'rpmLineGraph') {
      labels = ['Time', 'Actual', 'Target', 'Min', 'Max'];
    } else if (this.props.dataType === 'dateActualMaxMin') {
      labels = ['Time', 'Actual', 'Max', 'Min'];
    } else if (this.props.dataType === 'drillStateLineGraph') {
      labels = ['Time', 'Actual', 'Target', 'Min', 'Max'];
    } else {
      labels = ['Time', 'Actual', 'Max', 'Min'];
    }

    const graph = new Dygraph(this.graphContainerRef.current, data, {
      labelsUTC: this.isUTC,
      labels: labels,
      legend: 'always',
      labelsDiv: this.legendContainerRef.current,
    });
    this.setState({ graph });
  }

  updateGraph(yRange) {
    const data = this.prepData();
    const graph = this.state.graph;

    if (yRange === null) {
      // Updating after componentDidUpdate
      graph.updateOptions({ file: data });
      this.setState({ graph });
      return;
    }

    if (yRange.length === 1) {
      // User Clicked Reset
      graph.updateOptions({ file: data, valueRange: null }); // force autoRange
      const [min, max] = graph.yAxisRange();
      this.setState({ graph, yMin: min, yMax: max });
      return;
    }

    if (yRange.length === 2) {
      // User Controlled Case
      graph.updateOptions({
        file: data,
        valueRange: yRange,
      });
      this.setState({ graph });
    }
  }

  componentDidMount() {
    if (this.state.graph === null && this.props.data.length > 0) {
      this.createGraph();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.length > 0) {
      if (this.state.graph === null) {
        this.createGraph();
      } else {
        if (
          this.props.data.length !== prevProps.data.length ||
          this.props.data[0] !== prevProps.data[0]
        ) {
          this.updateGraph(null);
        }
      }
    } else if (this.props.data.length === 0 && this.state.graph !== null) {
      this.state.graph.destroy();
      this.setState({ graph: null });
      while (this.legendContainerRef.current.firstChild) {
        this.legendContainerRef.current.removeChild(
          this.legendContainerRef.current.firstChild,
        );
      }
    }
  }

  openSettingsModal() {
    const [min, max] = this.state.graph.yAxisRange();
    this.setState({ isSettingsModalOpen: true, yMin: min, yMax: max });
  }

  closeSettingsModal() {
    this.setState({ isSettingsModalOpen: false });
  }

  changeYMax(event) {
    this.setState({ yMax: parseFloat(event.target.value) });
  }

  changeYMin(event) {
    this.setState({ yMin: parseFloat(event.target.value) });
  }

  applyChanges(event) {
    this.updateGraph([this.state.yMin, this.state.yMax]);
  }

  resetYRange(event) {
    this.updateGraph([0]);
  }

  render() {
    return (
      <React.Fragment>
        <Card raised className="dygraph-line-graph" style={{height: "370px"}}>
          <CardHeader
            title={this.props.title}
            titleTypographyProps={{ align: "left", variant: "h6"}}
            action={
              this.state.graph !== null ? (
                <IconButton
                  id="line-graph-settings-button"
                  color="inherit"
                  onClick={this.openSettingsModal}
                >
                  <SettingsIcon />
                </IconButton>
              ) : null
            }
          />
          <CardContent className="line-graph-wrapper">
            {this.state.graph === null ? (
              <div className="graph-loading">
                <CircularProgress color="primary" />
              </div>
            ) : null}
            {this.props.data.length === 0 ? (
              <div className="graph-loading">
                <Tooltip title="No Data to Display">
                  <Error />
                </Tooltip>
              </div>
            ) : null}
            <div
              className="dygraph-legend-container"
              ref={this.legendContainerRef}
            />
            <div
              className="dygraph-graph-container"
              ref={this.graphContainerRef}
            />
          </CardContent>
        </Card>

        <Popover
          open={this.state.isSettingsModalOpen}
          onClose={this.closeSettingsModal}
          anchorEl={this.legendContainerRef.current}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        >
          <div className="line-graph-settings-popover-content">
            <TextField
              id="line-graph-settings-y-axis-max"
              label="Y Max"
              value={this.state.yMax}
              onChange={this.changeYMax}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              className="line-graph-settings-popover-content-y-range-input"
            />
            <TextField
              id="line-graph-settings-y-axis-min"
              label="Y Min"
              value={this.state.yMin}
              onChange={this.changeYMin}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              className="line-graph-settings-popover-content-y-range-input"
            />
            <div className="line-graph-settings-popover-content-buttons">
              <Button
                variant="outlined"
                color="secondary"
                onClick={this.applyChanges}
                className="line-graph-settings-popover-content-button"
              >
                Apply
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={this.resetYRange}
                className="line-graph-settings-popover-content-button"
              >
                Reset
              </Button>
            </div>
          </div>
        </Popover>
      </React.Fragment>
    );
  }
}
