import './resourcesView.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress  from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import ProcessView from './processView';
import React from 'react';
import Typography from '@material-ui/core/Typography';

export default class ResourcesView extends React.Component {
  render() {
    let that = this;
    let processNames = Object.keys(this.props.resources);

    if (this.props.connected === false ) {
       return (
         <React.Fragment>
       	   <Backdrop open={!this.props.connected}>
	   <Typography>Establishing Remote Connection...</Typography>
	      <CircularProgress color="inherit" />
	   </Backdrop>
         </React.Fragment>
       )}

    if (this.props.connected === false && processNames.length === 0) {
       return (
         <React.Fragment>
       	   <Backdrop open={!this.props.connected}>
	   <Typography>Establishing Remote Connection...</Typography>
	      <CircularProgress color="inherit" />
	   </Backdrop>
         </React.Fragment>
    )}

    return (
        <React.Fragment id="resources-content">
            <Grid item container xs={12} >
                {processNames.map(function (processName) {
                    if (processName !== 'allProcessesActive') {
                        return (
                            <ProcessView  name={processName}
                                          resources={that.props.resources}
                                          key={processName}
                            />
                        );
                    } else {
                        return null;
                    }
                })}
            </Grid >
        </React.Fragment>
    )};
}

