import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {
  createInputWithTooltip,
  createSubmitCloseButtons,
} from '../Common/CreateInputsHelperMethods';

class Roptimizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steadyStateWindow: props.roptimizerConfiguration.steadyStateWindow,
      steadyStateMin: props.roptimizerConfiguration.steadyStateMin,
      rpmStepSize: props.roptimizerConfiguration.rpmStepSize,
      ropStepSize: props.roptimizerConfiguration.ropStepSize,
    };

    this.updateInput = this.updateInput.bind(this);
    this.submitRoptimizerConfigurationRequest = this.submitRoptimizerConfigurationRequest.bind(
      this,
    );
  }

  updateInput(name) {
    let that = this;
    return function (event) {
      that.setState({ [name]: parseInt(event.target.value) });
    };
  }

  submitRoptimizerConfigurationRequest() {
    this.props.submitRoptimizerConfigurationRequest(this.state);
  }

  render() {
    const config = this.props.roptimizerConfiguration;
    return (
      <Grid item>
        <Card className="configuration-request-card" variant="outlined">
          <CardContent>
            <Grid container spacing={8} justify="center">
              {createInputWithTooltip(
                'steadyStateWindow',
                'Steady State Window',
                config.steadyStateWindow,
                this.state.steadyStateWindow,
                this.updateInput,
              )}
              {createInputWithTooltip(
                'steadyStateMin',
                'Steady State Min',
                config.steadyStateMin,
                this.state.steadyStateMin,
                this.updateInput,
              )}
              {createInputWithTooltip(
                'rpmStepSize',
                'RPM Step Size',
                config.rpmStepSize,
                this.state.rpmStepSize,
                this.updateInput,
              )}
              {createInputWithTooltip(
                'ropStepSize',
                'ROP Step Size',
                config.ropStepSize,
                this.state.ropStepSize,
                this.updateInput,
              )}
            </Grid>
          </CardContent>
          {createSubmitCloseButtons(
            this.submitRoptimizerConfigurationRequest,
            this.props.closeModal,
          )}
        </Card>
      </Grid>
    );
  }
}

export default Roptimizer;
