import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import Roptimizer from './Roptimizer';

import './RequestsModal.css';

class RequestsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitRoptimizerConfigurationRequest = this.submitRoptimizerConfigurationRequest.bind(this,);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  submitRoptimizerConfigurationRequest(configurationRequestData) {
    this.props.submitRoptimizerConfigurationRequest(
      this.props.socket,
      configurationRequestData,
    );
  }

  render() {
    return (
      <Dialog
        id="requests-modal"
        aria-labelledby="Settings Modal"
        aria-describedby="View current values and set targets for system configurations."
        open={this.state.isModalOpen}
        onClose={this.closeModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Roptimizer Configuration</DialogTitle>
        <DialogContent>
          <div className="requests-modal-content">
            <Roptimizer
              roptimizerConfiguration={this.props.roptimizerConfiguration}
              submitRoptimizerConfigurationRequest={
                this.submitRoptimizerConfigurationRequest
              }
              closeModal={this.closeModal}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default RequestsModal;
