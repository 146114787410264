import deepmerge from '../../helpers/deepmerge';
import {
  SOCKET_ON_DRILL_STATE,
} from '../actions/data';

export default function drillState(state, action) {
  if (typeof state === 'undefined') {
    return {
	valid: false,

	rop: {
	    actual: 0,
	    limit: 0,
	    mode: 0,
	    target: 0,
	    data: [],
	},
	wob: {
	    actual: 0,
	    limit: 0,
	    mode: 0,
	    target: 0,
	    data: [],
	},
	diffp: {
	    timestamp: 0,
	    actual: 0,
	    limit: 0,
	    mode: 0,
	    target: 0,
	    data: [],
	},
	torque: {
	    actual: 0,
	    limit: 0,
	    mode: 0,
	    target: 0,
	    data: [],
	},
    };
  }

  const newState = deepmerge({}, state);

  switch (action.type) {
      case SOCKET_ON_DRILL_STATE:
          if (Array.isArray(action.drillState) === false) {
              newState.valid = true;

              newState.rop.timestamp = action.drillState.rop.millisecondsEpochUTC;
              newState.rop.actual = action.drillState.rop.actual;
              newState.rop.target = action.drillState.rop.target;
              newState.rop.limit = action.drillState.rop.limit;
              newState.rop.mode = action.drillState.rop.mode;
	      
              newState.wob.timestamp = action.drillState.wob.millisecondsEpochUTC;
              newState.wob.actual = action.drillState.wob.actual;
              newState.wob.target = action.drillState.wob.target;
              newState.wob.limit = action.drillState.wob.limit;
              newState.wob.mode = action.drillState.wob.mode;
	      
              newState.diffp.timestamp = action.drillState.diffp.millisecondsEpochUTC;
              newState.diffp.actual = action.drillState.diffp.actual;
              newState.diffp.target = action.drillState.diffp.target;
              newState.diffp.limit = action.drillState.diffp.limit;
              newState.diffp.mode = action.drillState.diffp.mode;
	      
              newState.torque.timestamp = action.drillState.torque.millisecondsEpochUTC;
              newState.torque.actual = action.drillState.torque.actual;
              newState.torque.target = action.drillState.torque.target;
              newState.torque.limit = action.drillState.torque.limit;
              newState.torque.mode = action.drillState.torque.mode;
              newState.rop.data = newState.rop.data.concat(action.drillState.rop);
              newState.wob.data = newState.wob.data.concat(action.drillState.wob);
              newState.diffp.data = newState.diffp.data.concat(action.drillState.diffp);
              newState.torque.data = newState.torque.data.concat(action.drillState.torque);
	  }
          else
          {
              newState.rop.data = newState.rop.data.concat(action.drillState.map(idx => idx.rop));
              newState.wob.data = newState.wob.data.concat(action.drillState.map(idx => idx.wob));
              newState.diffp.data = newState.diffp.data.concat(action.drillState.map(idx => idx.diffp));
              newState.torque.data = newState.torque.data.concat(action.drillState.map(idx => idx.torque));
	  }

          if (newState.rop.data.length > process.env.REACT_APP_DATA_POINT_LIMIT) {
              newState.rop.data = newState.rop.data.slice(newState.rop.data.length - process.env.REACT_APP_DATA_POINT_LIMIT);
	  }

          if (newState.wob.data.length > process.env.REACT_APP_DATA_POINT_LIMIT) {
              newState.wob.data = newState.wob.data.slice(newState.wob.data.length - process.env.REACT_APP_DATA_POINT_LIMIT);
          }

          if (newState.diffp.data.length > process.env.REACT_APP_DATA_POINT_LIMIT) {
              newState.diffp.data = newState.diffp.data.slice(newState.diffp.data.length - process.env.REACT_APP_DATA_POINT_LIMIT);
          }

          if (newState.torque.data.length > process.env.REACT_APP_DATA_POINT_LIMIT) {
              newState.torque.data = newState.torque.data.slice(newState.torque.data.length - process.env.REACT_APP_DATA_POINT_LIMIT);
          }

          return newState;
    default:
      return state;
  }
}
